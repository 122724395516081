var t = {}, o;
function i() {
  if (o) return t;
  o = 1, Object.defineProperty(t, "__esModule", { value: !0 });
  function u() {
    for (var a = [], r = 0; r < arguments.length; r++)
      a[r] = arguments[r];
    return function() {
      for (var e = [], n = 0; n < arguments.length; n++)
        e[n] = arguments[n];
      return a.reduce(function(p, g, f) {
        var v = f === 0 ? p : [p];
        return g.apply(void 0, v);
      }, e);
    };
  }
  t.pipe = u;
  function s(a) {
    for (var r = [], e = 1; e < arguments.length; e++)
      r[e - 1] = arguments[e];
    return u.apply(void 0, r)(a);
  }
  return t.pipeWith = s, t;
}
var m = i();
export {
  m as t
};
