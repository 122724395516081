import {DataState} from '@joomcode/deprecated-utils/dataState';
import {DialogContentHeader} from 'components/DialogContent';
import {DialogErrorContent} from 'components/DialogErrorContent';
import {useWizard} from 'components/Wizard';
import {UserUpdatePayload} from 'lib/user/types';
import {User} from 'lib/user/types';
import {useCallback} from 'react';
import React, {useEffect, useMemo} from 'react';
import {Dialog, DialogContent} from 'uikit/Dialog';
import {FormView} from './FormView';
import {FormData} from './FormView/types';
import {getStepByFormData, convertUserPayloadToFormData, convertFormDataToUserPayload} from './FormView/utils';
import {messages} from './messages';
import {SuccessView} from './SuccessView';
import {useDialogAnalytics} from './useDialogAnalytics';

type Props = {
  dataState: DataState;
  dealInProgressSubtitleVisible: boolean;
  onClose: () => void;
  onSubmit: (data: UserUpdatePayload) => Promise<unknown>;
  onTryAgain: () => void;
  successVisible: boolean;
  user: User;
};

export function CoreDialog({
  dataState,
  onSubmit,
  user,
  onTryAgain,
  successVisible,
  dealInProgressSubtitleVisible,
  ...restProps
}: Props): React.ReactElement {
  const analyticEvents = useDialogAnalytics();
  const initialValues = useMemo(() => convertUserPayloadToFormData(user), [user]);
  const hasError = dataState === DataState.FAILED;

  const initialStep = useMemo(() => getStepByFormData(initialValues), [initialValues]);

  const wizard = useWizard({
    initialStep,
    onPrev: analyticEvents.onPrev,
  });
  const {step} = wizard;

  const onClose = useCallback(() => {
    analyticEvents.onClose({step});
    restProps.onClose();
  }, [restProps.onClose, analyticEvents.onClose, step]);

  useEffect(() => analyticEvents.onOpen({step}), [step]);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      analyticEvents.onStepSubmit({step});
      await onSubmit(convertFormDataToUserPayload(data));
      analyticEvents.onStepSuccessSubmit(data);
    },
    [onSubmit, analyticEvents.onStepSubmit, step],
  );

  const formVisible = !hasError && !successVisible;

  const title = useMemo(() => {
    if (wizard.step === 0) {
      return messages.step0Title;
    }
    if (wizard.step === 1) {
      return messages.step1Title;
    }
    return undefined;
  }, [wizard.step]);

  const subTitle = useMemo(() => {
    if (dealInProgressSubtitleVisible) {
      return messages.dealInProgressSubTitle;
    }
    if (wizard.step === 0) {
      return messages.step0SubTitle;
    }
    if (wizard.step === 1) {
      return messages.step1SubTitle;
    }
    return undefined;
  }, [dealInProgressSubtitleVisible, wizard.step]);

  useEffect(() => {
    if (successVisible) {
      analyticEvents.onSuccessView();
    }
  }, [successVisible]);

  return (
    <Dialog
      onBack={wizard.goPrev}
      onClose={onClose}
      width={424}
      withBackButton={formVisible && wizard.showBack}
      withCloseButton
    >
      {hasError && <DialogErrorContent onTryAgainClick={onTryAgain} />}
      {successVisible && <SuccessView />}
      {formVisible && (
        <DialogContent>
          <DialogContentHeader
            image='/illustrations/successDialog.png'
            imageSize={200}
            subtitle={subTitle}
            title={title}
          />
          <FormView initialValues={initialValues} onSubmit={handleSubmit} wizard={wizard} />
        </DialogContent>
      )}
    </Dialog>
  );
}
