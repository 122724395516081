import {DataState} from '@joomcode/deprecated-utils/dataState';
import {$order, loadOrderFx} from './index';

$order
  .on(loadOrderFx, (state) => ({
    ...state,
    dataState: DataState.LOADING,
  }))
  .on(loadOrderFx.doneData, (state, response) => ({
    ...state,
    data: response,
    dataState: DataState.LOADED,
  }))
  .on(loadOrderFx.failData, (state, error) => ({
    ...state,
    dataState: DataState.FAILED,
    error,
  }));
