import {Client} from 'lib/client';
import {PaymentSettings} from 'lib/paymentSettings/types';

type PaymentSettingsResponse = {
  payload: PaymentSettings;
};

export function loadPaymentSettings(client: Client): Promise<PaymentSettings> {
  return client.mainApi.post<PaymentSettingsResponse>('payment/settings/get').then(({body: {payload}}) => payload);
}
