function t(e) {
  const r = parseInt(e, 10);
  return r < 0 || r >= 4294967295 ? !0 : e !== String(r);
}
function o(e) {
  return Object.getOwnPropertyNames(e).filter((r) => Object.prototype.propertyIsEnumerable.call(e, r) && t(r));
}
export {
  o as getOwnEnumerableNonArrayIndexKeys,
  t as isNonArrayIndexKey
};
