import {DataState} from '@joomcode/deprecated-utils/dataState';
import {contactUs} from 'api/selfService/contactUs';
import {createStore, createClientEffect, createEvent} from 'lib/effector';
import {ContactUsDestination} from 'lib/selfService/types';
import {createRegistrationRequiredEvent} from 'models/registrationCallback/utils';

type ContactUsDialogState = {
  isOpen: boolean;
  payload?: {
    [k: string]: unknown;
    destination?: ContactUsDestination;
  };
  requestError?: unknown;
  requestState: DataState;
  source?: string;
};

const initialState: ContactUsDialogState = {
  isOpen: false,
  requestState: DataState.IDLE,
};

export const $contactUsDialog = createStore<ContactUsDialogState>('contactUsDialog', initialState);

export const openContactUsDialogUnsafe = createEvent<{
  [k: string]: unknown;
  destination?: ContactUsDestination;
} | void>();

export const openContactUsDialog = createRegistrationRequiredEvent(openContactUsDialogUnsafe, {
  opendialogPayload: {source: 'contactUs'},
});

export const closeContactUsDialog = createEvent();

export const sendContactUsRequestFx = createClientEffect(contactUs);
