import {AutocompleteMultiField} from 'components/FinalForm/AutocompleteMultiField';
import {useDevicevars} from 'lib/devicevars';
import {companyOperationFields} from 'lib/user/companyOperationField';
import {formatСompanyOperationField} from 'lib/user/companyOperationField/messages';
import React, {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {fieldMessages} from '../../messages';
import {FieldProps} from '../../types';

export const CompanyOperationField = ({fieldName, required}: FieldProps) => {
  const intl = useIntl();
  const getOptionLabel = useCallback((item: string) => formatСompanyOperationField(item, intl), [intl]);
  const {shouldReverseRegistrationFieldsOptions} = useDevicevars();

  const options = useMemo(
    () => (shouldReverseRegistrationFieldsOptions ? companyOperationFields.slice().reverse() : companyOperationFields),
    [companyOperationFields, shouldReverseRegistrationFieldsOptions],
  );

  return (
    <AutocompleteMultiField
      disableClearable
      freeSolo
      getOptionLabel={getOptionLabel}
      label={intl.formatMessage(fieldMessages.companyOperationFields)}
      name={fieldName}
      options={options}
      required={required}
    />
  );
};
