import {arrayToObject} from '@joomcode/deprecated-utils/array/toObject';
import {identity} from '@joomcode/deprecated-utils/function';
import {$catalogPromotions, resetCatalogPromotions, setCatalogPromotions} from './index';

$catalogPromotions.reset(resetCatalogPromotions);
$catalogPromotions.on(setCatalogPromotions, (state, promotions) => ({
  ...state,
  promotionById: arrayToObject(promotions, (item) => item.promotionId, identity),
  promotions,
}));
