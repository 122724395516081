import classNamesBind from 'classnames/bind';
import {ExtMessageDescriptor} from 'lib/intl/types';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import styles from './index.module.scss';

const cn = classNamesBind.bind(styles);

export type RadioButtonTestId = {
  input: unknown;
  label: unknown;
};

export type Props = TestIdProp<RadioButtonTestId> & {
  align?: 'center' | 'start' | 'end';
  checked?: boolean;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  disabledColor?: 'default' | 'gray';
  id?: string;
  label?: ExtMessageDescriptor | React.ReactNode;
  name?: string;
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
  value?: string;
};

export function RadioButton({
  disabled,
  disabledColor = 'default',
  id,
  label,
  name,
  value,
  children,
  align = 'start',
  checked,
  onChange,
  testId,
  className,
}: Props): React.ReactElement {
  return (
    <label
      className={cn(
        'container',
        `align-${align}`,
        {checked, disabled, enabled: !disabled, gray: disabledColor === 'gray'},
        className,
      )}
      data-test-id={testId}
    >
      <input
        checked={checked}
        className={styles.input}
        data-test-id={testId?.input}
        disabled={disabled}
        id={id}
        name={name}
        onChange={onChange}
        type='radio'
        value={value}
      />
      {children}
      {label && !children && (
        <span className={styles.label} data-test-id={testId?.label}>
          {label as React.ReactNode}
        </span>
      )}
    </label>
  );
}
