import {AuthOrigin, AuthViewName} from 'components/Auth/types';
import {BlogNavigation} from 'components/BlogNavigation';
import {Layout} from 'components/Layout';
import {Footer} from 'components/Main/Footer';
import {MainLayout} from 'components/Main/Layout';
import {MainContextValue, MainDecoratorProps, useMainContextValues, bindReason} from 'components/Main/lib';
import {MainContext} from 'components/Main/lib';
import {useAnalytics} from 'lib/analytics';
import {CommunicationReason} from 'lib/communication/types';
import {useEvent, useStore} from 'lib/effector';
import {signOutFx} from 'models/auth';
import {openAuthDialog} from 'models/auth/dialog';
import {openConsultationDialog} from 'models/consultation';
import {$user} from 'models/user';
import {useMemo, useEffect} from 'react';
import {useContext, useCallback} from 'react';

type Props = {children: React.ReactNode};

function Core({children}: Props): React.ReactElement {
  const analytics = useAnalytics();
  useEffect(() => {
    analytics.sendEvent({
      payload: {
        pageUrl: window.location.href,
      },
      type: 'blogOpen',
    });
  }, []);
  const {onOpenConsultationDialog, profile} = useContext(MainContext);
  const handleOpenConsultationDialog = useCallback(
    (section: string) => {
      onOpenConsultationDialog?.({pageUrl: window.location.href, section, source: 'contactUsFooter'});
    },
    [onOpenConsultationDialog],
  );

  return (
    <MainLayout
      footer={<Footer onConnect={handleOpenConsultationDialog} />}
      navigation={<BlogNavigation profile={profile} />}
    >
      {children}
    </MainLayout>
  );
}

export function BlogLayout({children}: Props): React.ReactElement {
  const user = useStore($user);

  const handleSignOut = useEvent(signOutFx);
  const handleOpenConsultationDialog = useEvent(openConsultationDialog);
  const baseContextValue = useMainContextValues();

  const handleOpenAuthDialogEvent = useEvent(openAuthDialog);
  const handleOpenAuthDialog = useCallback(
    (source?: string) => {
      handleOpenAuthDialogEvent({
        origin: AuthOrigin.GENERAL,
        source,
        view: AuthViewName.SIGN_IN_BY_PHONE,
      });
    },
    [handleOpenAuthDialogEvent],
  );

  const profile = useMemo<MainDecoratorProps['profile']>(
    () => ({
      onSignIn: handleOpenAuthDialog,
      onSignOut: handleSignOut,
      user,
    }),
    [user, handleSignOut, handleOpenAuthDialog],
  );

  const mainContextValue: MainContextValue = useMemo(() => {
    return {
      ...baseContextValue,
      onOpenConsultationDialog: bindReason(handleOpenConsultationDialog, CommunicationReason.CONSULTATION_FROM_BLOG),
      profile,
    };
  }, [profile, baseContextValue, handleOpenConsultationDialog]);

  return (
    <Layout>
      <MainContext.Provider value={mainContextValue}>
        <Core>{children}</Core>
      </MainContext.Provider>
    </Layout>
  );
}
