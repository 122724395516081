import {useAnalytics} from 'lib/analytics';
import {useAppEnv} from 'lib/appEnv';
import {useCallback, useMemo} from 'react';
import {FormData} from './FormView/types';

type Payload = {data?: FormData; step: number};

type ReturnShape = {
  onClose(data: Payload): void;
  onOpen(data: Payload): void;
  onPrev(data: Payload): void;
  onStepSubmit(data: Payload): void;
  onStepSuccessSubmit(data: FormData): void;
  onSuccessView(): void;
};

export function useDialogAnalytics(): ReturnShape {
  const appEnv = useAppEnv();
  const analytics = useAnalytics();
  const pageUrl = window.location.href;
  const payload = useMemo(
    () => ({
      pageUrl,
      popupType: 'registationSelfService',
      source: appEnv.currentPageName,
    }),
    [appEnv.currentPageName, pageUrl],
  );

  const onPrev: ReturnShape['onPrev'] = useCallback(
    ({step}) => {
      analytics.sendEvent({
        payload,
        type: 'popupFormBack',
      });
      analytics.sendEvent({
        payload: {
          pageUrl,
          step,
        },
        type: 'registrationPrevStep',
      });
    },
    [analytics.sendEvent, payload, pageUrl],
  );

  const onClose: ReturnShape['onClose'] = useCallback(
    ({step}) => {
      analytics.sendEvent({
        payload,
        type: 'popupClose',
      });
      analytics.sendEvent({
        payload: {
          pageUrl,
          step,
        },
        type: 'registrationClose',
      });
    },
    [analytics.sendEvent, payload, pageUrl],
  );

  const onOpen: ReturnShape['onOpen'] = useCallback(
    ({step}) => {
      analytics.sendEvent({
        payload,
        type: 'popupOpen',
      });
      analytics.sendEvent({
        payload: {
          pageUrl,
          step,
        },
        type: 'registrationOpen',
      });
    },
    [analytics.sendEvent, payload, pageUrl],
  );

  const onStepSubmit: ReturnShape['onStepSubmit'] = useCallback(
    ({step}) => {
      analytics.sendEvent({
        payload,
        type: 'popupFormSubmit',
      });
      analytics.sendEvent({
        payload: {pageUrl, step},
        type: 'registrationSubmitStep',
      });
    },
    [analytics.sendEvent, payload, pageUrl],
  );

  const onStepSuccessSubmit: ReturnShape['onStepSuccessSubmit'] = useCallback(
    (data) => {
      if (data?.email && data?.firstName) {
        analytics.dataLayer({
          event: 'reg_personal_data',
          formInfo: data,
        });
      }
      if (data?.companyOperationFields?.length) {
        analytics.dataLayer({
          event: 'reg_company_info',
          formInfo: data,
        });
      }
      if (data?.categoryIds?.length) {
        analytics.dataLayer({
          event: 'reg_categories',
          formInfo: data,
        });
      }
      if (data?.cnpj) {
        analytics.dataLayer({
          event: 'reg_cnpj',
          formInfo: data,
        });
      }
    },
    [analytics.dataLayer],
  );

  const onSuccessView: ReturnShape['onSuccessView'] = useCallback(() => {
    analytics.dataLayer({
      event: 'reg_done',
    });
  }, [analytics.dataLayer]);

  return {
    onClose,
    onOpen,
    onPrev,
    onStepSubmit,
    onStepSuccessSubmit,
    onSuccessView,
  };
}
