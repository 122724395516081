import {createDataStore} from '@joomcode/joompro-core';
import {loadPaymentSettings} from 'api/paymentSettings/paymentSettings';
import {createClientEffect, createEvent} from 'lib/effector';
import {PaymentSettings} from 'lib/paymentSettings/types';

export const [$paymentSettings, paymentSettingsBinders] = createDataStore<PaymentSettings>(
  {data: {}},
  {name: 'paymentSetting'},
);

export const loadPaymentSettingsFx = createClientEffect(loadPaymentSettings);

export const resetPaymentSettings = createEvent();
