import { levelMapping } from '../sentry';
import { Provider } from '../providers/Provider';
import { Level } from '../common/Level';
import { stringify } from '../utils';
export class SentryProvider extends Provider {
    constructor(sentry, config) {
        super(config);
        this.sentry = sentry;
    }
    processInternal({ name, level, args, meta }) {
        if (level.value >= Level.ERROR.value) {
            const ecs = args[0];
            if (typeof ecs === 'object') {
                const { error, ...rest } = ecs;
                // eslint-disable-next-line no-underscore-dangle
                const originalError = error?.__originalError;
                if (originalError && originalError instanceof Error) {
                    this.sentry.captureException(originalError, {
                        extra: {
                            ...meta,
                            ...rest,
                        },
                        level: levelMapping[level.name],
                        tags: {
                            from: 'logger',
                            logger: name,
                        },
                    });
                }
            }
        }
        else {
            this.sentry.captureMessage(args.map(stringify).join(' '), {
                extra: meta,
                level: levelMapping[level.name],
                tags: {
                    from: 'logger',
                    logger: name,
                },
            });
        }
    }
}
