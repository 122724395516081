import { ID_REG_EXP as n, FORBIDDEN_ID as r, FRIENDLY_ID_REG_EXP as e, NEW_ID_REG_EXP as u } from "../patterns/index.js";
function o(t) {
  return t.id;
}
function I(t) {
  return n.test(t) && t !== r;
}
function E(t) {
  return e.test(t);
}
function _(t) {
  return I(t) ? t : null;
}
function s(t) {
  return u.test(t);
}
export {
  o as getId,
  E as isFriendlyId,
  I as isId,
  s as isNewFormatId,
  _ as parseId
};
