import { Provider } from '../providers/Provider';
import { Level } from '../common/Level';
import { isNode } from '../utils/isNode';
function log(type, event) {
    const { meta: { requestId = '-' }, name, args, timestamp, level, } = event;
    const consoleArgs = args.map((item) => {
        if (isNode() && item instanceof Error) {
            return {
                message: item.message,
                name: item.name,
                stack: item.stack,
            };
        }
        return item;
    });
    const timeString = new Date(timestamp).toISOString();
    const signature = `${level.name} [${name}][${timeString}][#${requestId}]`;
    if (globalThis.console[type]) {
        globalThis.console[type](signature, ...consoleArgs);
    }
    else {
        globalThis.console.log(signature, ...consoleArgs);
    }
}
export class ConsoleProvider extends Provider {
    processInternal(data) {
        if (!global.console) {
            return;
        }
        switch (data.level.value) {
            case Level.FATAL.value:
            case Level.ERROR.value:
                log('error', data);
                break;
            case Level.WARN.value:
                log('warn', data);
                break;
            case Level.INFO.value:
                log('info', data);
                break;
            case Level.TRACE.value:
                log('trace', data);
                break;
            default:
                log('log', data);
                break;
        }
    }
}
