import {sample, attach, Event} from 'effector';
import {createEffect, createEvent} from 'lib/effector';
import {CancelRegistrationError} from 'lib/errors/CancelRegistrationError';
import {User} from 'lib/user/types';
import {addRegistrationCallback} from 'models/registrationCallback';
import {openSsRegistrationDialog, OpenRegistrationDialogPayload} from 'models/ssRegistrationDialog';
import {$user} from 'models/user';

type Callback<T, R> = ((payload: T) => Promise<R>) | Event<T>;

type Props<T, R> = {
  cb?: Callback<T, R>;
  params: T;
  user: User;
};

type RegistrationRequiredEffectOptions = {
  opendialogPayload?: OpenRegistrationDialogPayload;
  skipSelfServiceData?: boolean;
};

export const createRegistrationRequiredEffect = <T, R>(
  callback?: Callback<T, R>,
  options: RegistrationRequiredEffectOptions = {},
) => {
  return attach({
    effect: createEffect(async ({user, cb, params}: Props<T, R>) => {
      if (options.skipSelfServiceData && !user.anonymous) {
        return cb?.(params);
      }

      if (user.isSelfServiceDataFilled) {
        return cb?.(params);
      }

      return new Promise((resolve, reject) => {
        addRegistrationCallback(async ({state}) => {
          if (state === 'filled') {
            try {
              return resolve(await cb?.(params));
            } catch (error) {
              return reject(error);
            }
          }

          return reject(new CancelRegistrationError());
        });

        openSsRegistrationDialog({...options.opendialogPayload, pageUrl: window.location.href});
      });
    }),
    mapParams: (params: T, user): Props<T, R> => ({
      cb: callback,
      params,
      user,
    }),
    source: $user,
  });
};

export const createRegistrationRequiredEvent = <T, R>(
  callback: Callback<T, R>,
  options: RegistrationRequiredEffectOptions = {},
) => {
  const event = createEvent<T>();

  sample({
    clock: event,
    target: createRegistrationRequiredEffect(callback, options),
  });

  return event;
};
