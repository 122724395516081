export class Verbosity {
    constructor(name, value) {
        this.name = name;
        this.value = value;
    }
}
export const Level = {
    OFF: new Verbosity('OFF', Infinity),
    FATAL: new Verbosity('FATAL', 50000),
    ERROR: new Verbosity('ERROR', 40000),
    WARN: new Verbosity('WARN', 30000),
    INFO: new Verbosity('INFO', 20000),
    DEBUG: new Verbosity('DEBUG', 10000),
    TRACE: new Verbosity('TRACE', 5000),
    ALL: new Verbosity('ALL', 0),
};
