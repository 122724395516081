import {ContactUsRequestDialog as ContactUsRequestDialogBase} from 'components/ContactUsRequestDialog';
import {FormData} from 'components/ContactUsRequestDialog/types';
import {useAnalytics} from 'lib/analytics';
import {useAppEnv} from 'lib/appEnv';
import {useEvent, useStore} from 'lib/effector';
import {$contactUsDialog, sendContactUsRequestFx, closeContactUsDialog} from 'models/selfService/contactUs';
import {$user} from 'models/user';
import React, {useCallback} from 'react';

export function ContactUsRequestDialog(): React.ReactElement | null {
  const {payload, isOpen, requestState} = useStore($contactUsDialog);
  const onCloseContactUsDialog = useEvent(closeContactUsDialog);
  const sendContactUsRequest = useEvent(sendContactUsRequestFx);
  const analytics = useAnalytics();
  const appEnv = useAppEnv();
  const user = useStore($user);

  const onSubmit = useCallback(
    (data: FormData) => {
      sendContactUsRequest({...payload, ...data});

      analytics.sendEvent({
        payload: {
          message: data.text,
          pageUrl: appEnv.currentPageName,
          source: appEnv.currentPageName,
          userId: user.id,
        },
        type: 'contactUsRequestSubmit',
      });
    },
    [user, payload, analytics, sendContactUsRequest],
  );

  if (isOpen) {
    return (
      <ContactUsRequestDialogBase onClose={onCloseContactUsDialog} onSubmit={onSubmit} requestState={requestState} />
    );
  }

  return null;
}
