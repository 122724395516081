import {Client} from 'lib/client';
import {FullOrder} from 'lib/order/types';

type OrderRequest = {
  dealId: string;
};

type OrderResponse = {
  payload: FullOrder;
};

export function loadOrder(client: Client, {dealId}: OrderRequest): Promise<FullOrder> {
  return client.mainApi
    .post<OrderResponse>('selfService/order/', {body: {dealId}})
    .then(({body: {payload}}): FullOrder => payload);
}
