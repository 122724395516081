import { Level } from '../common/Level';
const isDev = process.env.NODE_ENV !== 'production';
export function jsonToLevelConfig(json) {
    if (!json) {
        return undefined;
    }
    const result = {};
    let hasKeys = false;
    Object.entries(json).forEach(([key, value]) => {
        const level = Level[value];
        if (level) {
            result[key] = level;
            hasKeys = true;
        }
    });
    return hasKeys ? result : undefined;
}
export function jsonStringToLevelConfig(str) {
    try {
        const level = Level[str];
        if (level) {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            return { '*': level };
        }
        return jsonToLevelConfig(JSON.parse(str));
    }
    catch (ex) {
        if (isDev) {
            // eslint-disable-next-line no-console
            console.error(ex);
        }
        return undefined;
    }
}
