import EventEmitter from 'component-emitter';
import { EventType } from './Event';
import { Logger } from './Logger';
export class LoggerFactory extends EventEmitter {
    constructor(meta = {}) {
        super();
        this.getRequestMeta = () => ({});
        this.handleLog = async (data) => {
            const meta = this.getMeta();
            this.providers.forEach((provider) => provider.process({ ...data, meta }));
        };
        this.loggers = {};
        this.providers = [];
        this.meta = meta;
    }
    getLogger(name = 'main') {
        let logger = this.loggers[name];
        if (!logger) {
            logger = new Logger(name);
            logger.on(EventType.LOG, this.handleLog);
            this.loggers[name] = logger;
        }
        return logger;
    }
    addProvider(provider) {
        this.providers.push(provider);
        return this;
    }
    setRequestMetaMiddleware(getter) {
        this.getRequestMeta = getter;
        return this;
    }
    addMeta(meta) {
        this.meta = { ...this.meta, ...meta };
        return this;
    }
    getMeta() {
        return { ...this.meta, ...this.getRequestMeta() };
    }
}
