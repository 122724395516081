export function removeCircularProperties(obj, { replacer } = {}) {
    const seen = new Set([obj]);
    function processObject(obj) {
        if (typeof obj !== 'object' || obj === null) {
            // Base case: return primitive values as is
            return obj;
        }
        if (Array.isArray(obj)) {
            // Process each array element
            return obj.map((item) => processObject(item));
        }
        const newObj = {};
        Object.entries(obj).forEach(([key, value]) => {
            if (typeof value === 'object' && value !== null) {
                if (seen.has(value)) {
                    newObj[key] = replacer;
                }
                else {
                    seen.add(value);
                    // Recursively process nested objects
                    newObj[key] = processObject(value);
                    seen.delete(value);
                }
            }
            else {
                newObj[key] = value;
            }
        });
        return newObj;
    }
    return processObject(obj);
}
