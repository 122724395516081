import {OnErrorFn} from '@formatjs/intl';
import {StyledEngineProvider} from '@mui/material/styles';
import {ErrorBoundary} from '@sentry/react';
import {AppEnv} from 'components/AppEnv';
import {Toaster} from 'components/Toaster';
import {config} from 'config';
import {AuthDialog} from 'containers/AuthDialog';
import {ConsultationDialog} from 'containers/ConsultationDialog';
import {ContactUsRequestDialog} from 'containers/ContactUsRequestDialog';
import {LoyaltyConsultationDialog} from 'containers/LoyaltyConsultationDialog';
import {ModalsManagerProvider} from 'containers/ModalsManagerProvider';
import {PageLeaveDialog} from 'containers/PageLeaveDialog';
import {PartnersConsultationDialog} from 'containers/PartnersConsultationDialog';
import {PaymentsConsultationDialog} from 'containers/PaymentsConsultationDialog';
import {PaymentsPartnersDialog} from 'containers/PaymentsPartnersDialog';
import {PromocodeContextProvider} from 'containers/PromocodeContextProvider';
import {ReferralDialog} from 'containers/ReferralDialog';
import {RegistrationDialog} from 'containers/RegistrationDialog';
import {ValueMap} from 'effector';
import {enableMapSet} from 'immer';
import {AnalyticsContext} from 'lib/analytics';
import {isEventsEnabled} from 'lib/analytics/events';
import {isGtmEnabled, startBounceCheck} from 'lib/analytics/utils';
import {BaseUrlContext} from 'lib/baseUrl';
import {Client, ClientContext} from 'lib/client';
import {CurrencyContext} from 'lib/currency';
import {DevicevarsProvider} from 'lib/devicevars';
import {StateManager, StateManagerProvider} from 'lib/effector';
import {isFunction, isRecord} from 'lib/guards';
import {getAcceptedLanguage} from 'lib/language';
import {LanguageContext} from 'lib/language/context';
import {Language} from 'lib/language/types';
import {commonLogger, intlLogger} from 'lib/logger';
import {safeDumpTransportResponse} from 'lib/logger/converters';
import {initSentry} from 'lib/logger/sentry';
import {setPageLeaveDialogHandlers, removePageLeaveDialogHandlers} from 'lib/pageLeave';
import {extractPromocodeFromQuery} from 'lib/promocode';
import {reverseUrl, getPageNameByPathname} from 'lib/router';
import 'models';
import {isTransportResponse} from 'lib/transport/types';
import {extractUtmParameters, parseUtmParameters, stringifyUtmParameters} from 'lib/utm';
import {loadRootCategoriesFx} from 'models/categories';
import {checkConsultationDialog} from 'models/consultation';
import {$canOpenPageLeaveDialog, openPageLeaveDialog} from 'models/pageLeave';
import {validateAndApplyPromocode} from 'models/promocode';
import {setServerDate} from 'models/serverDate';
import {openTimeoutedRegistation} from 'models/ssRegistrationDialog/timeoutedRegistration';
import {$user, loadUserFx} from 'models/user';
import {AppContext, AppInitialProps, AppProps, NextWebVitalsMetric} from 'next/app';
import Head from 'next/head';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import React from 'react';
import {IntlProvider} from 'react-intl';
import 'styles/globals.scss';
import 'styles/mui.scss';

enableMapSet();

type Messages = Record<string, string>;

type ExtendedComponentProps = {
  autoRegistrationDisabled?: boolean;
  loginRequired?: boolean;
};

type ExtendedAppInitialProps = AppInitialProps & {
  client?: Client;
  externalLink?: string;
  language: Language;
  messages?: Messages;
  store?: StateManager | ValueMap;
};

type ExtendedAppProps = AppProps &
  ExtendedAppInitialProps & {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Component: AppProps['Component'] & ExtendedComponentProps;
  };

type ExtendedAppContext = AppContext & {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Component: AppContext['Component'] & ExtendedComponentProps;
};

let clientSideClient: Client;
let clientSideStore: StateManager;

async function loadMessages(language: Language): Promise<Messages> {
  let messages;

  try {
    const locale = config.i18nLocaleByLanguage[language];
    if (!locale) {
      throw new Error(`Cannot find translations file for language ${language}`);
    }

    /* eslint-disable import/no-named-as-default-member */
    const clientMessages = (await import(`../translations/${locale}.json`)).default;
    const libsMessages = (await import(`../libs-translations/${locale}.ts`)).default;
    /* eslint-enable import/no-named-as-default-member */

    messages = {...clientMessages, ...libsMessages};
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    messages = {};
  }

  return messages;
}

export function reportWebVitals(webVitalsMetric: NextWebVitalsMetric): void {
  if (__CLIENT__) {
    if (clientSideClient) {
      clientSideClient.analytics.dataLayer({
        event: 'webVitals',
        webVitalsMetric,
      });
    }
  }
}

// TODO: PRO-4713 вынести вычисление baseUrl за пределеы класса Client
const getDefaultBaseUrl = (() => {
  let cachedOrigin: string | undefined;
  let preparedDefaultBaseUrl: URL | undefined;

  return (): URL => {
    if (!preparedDefaultBaseUrl || cachedOrigin !== config.defaultOrigin) {
      cachedOrigin = config.defaultOrigin;
      preparedDefaultBaseUrl = new URL(config.defaultOrigin);
    }

    return preparedDefaultBaseUrl;
  };
})();

/* eslint-disable react/sort-comp */
export default class MyApp<P extends ExtendedAppProps> extends React.Component<P> {
  messages: Messages;

  client: Client;

  store: StateManager;

  bounceCheck: ReturnType<typeof startBounceCheck> | undefined;

  timeoutRegistration: ReturnType<typeof setTimeout>;

  constructor(props: P, context?: unknown) {
    super(props, context);
    const {client, messages, store, language} = props;

    this.messages = messages || {};

    if (client instanceof Client) {
      this.client = client;
    } else if (isRecord(client)) {
      this.client = new Client(client);
    } else {
      throw new Error('Client config is failed');
    }

    if (store instanceof StateManager) {
      this.store = store;
    } else {
      this.store = new StateManager({client: this.client, language}, store);
      this.store.client = this.client;
    }

    if (typeof window !== 'undefined') {
      clientSideClient = this.client;
      clientSideStore = this.store;
    }
  }

  static async getInitialProps(appContext: ExtendedAppContext): Promise<ExtendedAppInitialProps> {
    const {Component, ctx, router} = appContext;

    const isErrorPage = getPageNameByPathname(router.pathname) === 'error';
    const language = getAcceptedLanguage(router.locale);
    const preferences = {
      currency: config.defaultCurrency,
      language,
    };

    let clientInitialized = false;
    let client;
    let store;
    let pageProps;
    let messages;
    let externalLink;

    if (__SERVER__) {
      client = new Client({preferences, router}, ctx.req);
      client.cookies.language = preferences.language;
      store = new StateManager({client, language});
      store.client = client;

      try {
        await client.initialize();
        clientInitialized = true;
      } catch (err) {
        const cleanedError = isTransportResponse(err) ? safeDumpTransportResponse(err) : err;
        commonLogger.error('App: Failed to update or create device', {
          err: cleanedError,
          isErrorPage,
        });

        if (!isErrorPage) {
          if (isTransportResponse(err) && ctx.res && err.status === 429) {
            ctx.res.writeHead(err.status);
            ctx.res.end(JSON.stringify(err.body));
            return {
              language,
              pageProps: {},
            };
          }

          throw err;
        }
      }
    } else {
      client = clientSideClient;
      store = clientSideStore;
    }

    // enhance ctx
    ctx.client = client;
    ctx.store = store;

    if (__SERVER__) {
      // UTM Parameters and External Link
      let queryUtm: string | undefined;
      try {
        queryUtm = stringifyUtmParameters(extractUtmParameters(ctx.query));
      } catch (err) {
        commonLogger.error('UTM: Failed to stringify utm parameters', err);
      }

      if (queryUtm && queryUtm !== client.cookies.utmParameters) {
        client.cookies.utmParameters = queryUtm;
        externalLink = ctx.req?.url;
      }

      // Promocode
      const promocode = extractPromocodeFromQuery(ctx.query);

      if (clientInitialized) {
        // Initialize store
        await store.dispatch(loadUserFx);
        await store.dispatch(loadRootCategoriesFx);
        await store.dispatch(setServerDate, new Date());

        if (promocode) {
          await store.dispatch(validateAndApplyPromocode, promocode);
        }
      }

      messages = await loadMessages(language);
      client.cookies.applyToResponse(ctx.res!);
    }

    const user = store.getState($user);

    if (Component.loginRequired && user.anonymous) {
      const redirectUrl = reverseUrl.auth(router.asPath);
      if (ctx.res) {
        /* eslint-disable @typescript-eslint/naming-convention */
        ctx.res.writeHead(302, {Location: `/${language}${redirectUrl}`}).end();
        /* eslint-enable @typescript-eslint/naming-convention */
      } else {
        router.replace(redirectUrl);
      }
    } else if (isFunction(Component.getInitialProps)) {
      pageProps = await Component.getInitialProps(ctx);
    }

    return {
      client,
      externalLink,
      language,
      messages,
      pageProps,
      store,
    };
  }

  componentDidMount(): void {
    const user = this.store.getState($user);
    if (isGtmEnabled(user)) {
      this.client.analytics.enableGtm();
    }

    if (isEventsEnabled(this.client.config)) {
      this.client.analytics.enableEvents();
    }
    this.analyticsSessionStarted(this.props.externalLink);
    this.analyticsPageOpened();

    // Progress
    NProgress.configure({showSpinner: false});
    const {router} = this.props;
    router.events.on('routeChangeStart', this.progressStart);
    router.events.on('routeChangeComplete', this.progressDone);
    router.events.on('routeChangeError', this.progressDone);

    // Analytics
    router.events.on('routeChangeStart', this.analyticsPageLeave);
    router.events.on('routeChangeComplete', this.analyticsPageOpened);

    // Page leave dialog
    setPageLeaveDialogHandlers(this.client.cookies, this.openPageLeaveDialog);

    // Page close/hide
    document.addEventListener('visibilitychange', this.handleVisibilityChange);

    if (__CLIENT__) {
      if (config.releaseStage === 'production' || config.releaseStage === 'staging') {
        initSentry();
      }

      this.store.dispatch(checkConsultationDialog);
    }

    if (!this.props.Component.autoRegistrationDisabled) {
      this.timeoutRegistration = setTimeout(() => {
        this.store.dispatch(openTimeoutedRegistation, {
          pageUrl: window.location.href,
          source: 'openTimeoutedRegistation',
        });
      }, 20_000);
    }
  }

  componentWillUnmount(): void {
    const {router} = this.props;
    // Progress
    router.events.off('routeChangeStart', this.progressStart);
    router.events.off('routeChangeComplete', this.progressDone);
    router.events.off('routeChangeError', this.progressDone);

    // Analytics
    router.events.off('routeChangeStart', this.analyticsPageLeave);
    router.events.off('routeChangeComplete', this.analyticsPageOpened);

    // Page leave dialog
    removePageLeaveDialogHandlers();

    // Page close/hide
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);

    clearTimeout(this.timeoutRegistration);
  }

  progressStart = (): void => {
    NProgress.start();
  };

  progressDone = (): void => {
    NProgress.done();
  };

  analyticsSessionStarted = (externalLink?: string): void => {
    const {analytics} = this.client;

    analytics.sendEvent(
      {
        payload: {
          pageUrl: window.location.href,
        },
        type: 'sessionStart',
      },
      {immediately: true},
    );
    analytics.dataLayer({event: 'session.session_start'});

    if (externalLink) {
      analytics.sendEvent({
        payload: {
          link: externalLink,
          pageUrl: window.location.href,
          parsedUtm: parseUtmParameters(this.client.cookies.utmParameters),
          referrer: document.referrer,
        },
        type: 'externalLink',
      });
    }
  };

  analyticsPageOpened = (): void => {
    const {router} = this.props;
    const {analytics} = this.client;

    analytics.dataLayer({
      event: 'pageView',
      locale: router.locale!,
      pagePath: router.asPath,
    });

    analytics.sendEvent({
      payload: {
        pageName: getPageNameByPathname(router.pathname),
        pageUrl: window.location.href,
      },
      type: 'pageView',
    });

    if (this.bounceCheck) {
      this.bounceCheck.abort();
    }
    this.bounceCheck = startBounceCheck(analytics, window.location.href);
  };

  analyticsPageLeave = (): void => {
    const {router} = this.props;
    const {analytics} = this.client;

    analytics.dataLayer({
      event: 'pageLeave',
      locale: router.locale!,
      pagePath: router.asPath,
    });

    analytics.sendEvent({
      payload: {
        pageName: getPageNameByPathname(router.pathname),
        pageUrl: window.location.href,
      },
      type: 'pageLeave',
    });

    this.bounceCheck?.forceSend('pageClosed');
  };

  openPageLeaveDialog = (): void => {
    const {
      store,
      client: {cookies},
      props: {language},
    } = this;

    if (language === Language.RU || language === Language.EN) {
      if (store.getState($canOpenPageLeaveDialog)) {
        cookies.siteLeaveDialog = 'shown';
        store.dispatch(openPageLeaveDialog);
      }
    }
  };

  handleVisibilityChange = (): void => {
    if (document.visibilityState === 'hidden') {
      this.bounceCheck?.forceSend('pageClosed');
      this.client.analytics.dataLayer({
        event: 'session.visibility_hide_debug',
      });
    }
  };

  handleIntlError: OnErrorFn = (error) => {
    if (__DEVELOPMENT__) {
      if (error.code === 'MISSING_TRANSLATION') {
        return;
      }
    }
    intlLogger.error('IntlProvider', error);
  };

  renderErrorPage(): React.ReactElement {
    const {Component, pageProps} = this.props;
    let baseUrl: URL;

    // TODO: PRO-4713 вынести вычисление baseUrl за пределеы класса Client
    try {
      baseUrl = this.client.config.baseUrl;
      if (!baseUrl || !(baseUrl instanceof URL)) {
        throw new Error('BaseUrl is not prepared');
      }
    } catch (e) {
      baseUrl = getDefaultBaseUrl();
    }

    /* eslint-disable react/jsx-props-no-spreading */
    return (
      <BaseUrlContext.Provider value={baseUrl}>
        <Component {...pageProps} />
      </BaseUrlContext.Provider>
    );
    /* eslint-enable react/jsx-props-no-spreading */
  }

  renderPage(): React.ReactElement {
    const {Component, pageProps} = this.props;

    return (
      <ClientContext.Provider value={this.client}>
        <BaseUrlContext.Provider value={this.client.config.baseUrl}>
          <DevicevarsProvider>
            <StateManagerProvider value={this.store}>
              <PromocodeContextProvider>
                <ModalsManagerProvider>
                  <AnalyticsContext.Provider value={this.client.analytics}>
                    <StyledEngineProvider injectFirst>
                      <Head>
                        <meta content='#ffffff' name='theme-color' />
                        <link href='/ico/pro/favicon-96x96.png' rel='icon' sizes='96x96' type='image/png' />
                        <link href='/ico/pro/apple-icon-180x180.png' rel='apple-touch-icon' sizes='180x180' />
                        <link href='/ico/pro/manifest.json' rel='manifest' />
                      </Head>
                      <Toaster>
                        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                        <Component {...pageProps} />
                        <AuthDialog />
                        <PageLeaveDialog />
                        <ConsultationDialog />
                        <PartnersConsultationDialog />
                        <PaymentsConsultationDialog />
                        <PaymentsPartnersDialog />
                        <LoyaltyConsultationDialog />
                        <RegistrationDialog />
                        <ContactUsRequestDialog />
                        <ReferralDialog />
                      </Toaster>
                    </StyledEngineProvider>
                  </AnalyticsContext.Provider>
                </ModalsManagerProvider>
              </PromocodeContextProvider>
            </StateManagerProvider>
          </DevicevarsProvider>
        </BaseUrlContext.Provider>
      </ClientContext.Provider>
    );
  }

  render(): React.ReactElement {
    const {language, router} = this.props;
    const currentPageName = getPageNameByPathname(router.pathname);

    return (
      <ErrorBoundary>
        <IntlProvider
          defaultLocale={config.defaultLocale}
          locale={language}
          messages={this.messages}
          onError={this.handleIntlError}
        >
          <LanguageContext.Provider value={language}>
            <CurrencyContext.Provider value={config.defaultCurrency}>
              <AppEnv env={{currentPageName}}>
                {currentPageName === 'error' ? this.renderErrorPage() : this.renderPage()}
              </AppEnv>
            </CurrencyContext.Provider>
          </LanguageContext.Provider>
        </IntlProvider>
      </ErrorBoundary>
    );
  }
}
