function r(n) {
  return typeof n == "object" && n !== null && !Array.isArray(n);
}
function o(n) {
  return typeof n == "number";
}
function t(n) {
  return typeof n == "boolean";
}
function i(n) {
  return typeof n == "string";
}
function u(n) {
  return typeof n == "function";
}
function e(n) {
  return Array.isArray(n);
}
export {
  e as isArray,
  t as isBoolean,
  u as isFunction,
  o as isNumber,
  r as isRecord,
  i as isString
};
