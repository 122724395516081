import {DataState} from '@joomcode/deprecated-utils/dataState';
import {updateUser} from 'api/user';
import {AuthOrigin} from 'components/Auth/types';
import {attach} from 'effector';
import {createStore, createEvent, createClientEffect} from 'lib/effector';
import {UserUpdatePayload} from 'lib/user/types';

export type SsRegistationDialogState = {
  dataState: DataState;
  error?: Error;
  opened: boolean;
  showCheckoutPausedMessage: boolean;
  successVisible: boolean;
};

const initialSsDialogState: SsRegistationDialogState = {
  dataState: DataState.IDLE,
  opened: false,
  showCheckoutPausedMessage: false,
  successVisible: false,
};

export const $ssRegistrationDialog = createStore<SsRegistationDialogState>(
  'ssRegistrationDialog',
  initialSsDialogState,
);

export type RegistrationOriginState = {
  value: AuthOrigin;
};

export const $registrationOrigin = createStore<RegistrationOriginState>('registrationOrigin', {
  value: AuthOrigin.GENERAL,
});

export type OpenRegistrationDialogPayload = {
  pageUrl?: string;
  showCheckoutPausedMessage?: boolean;
  source?: string;
};

export const openSsAuthDialog = createEvent<{pageUrl: string; source: string}>();

export const openSsRegistrationDialog = createEvent<OpenRegistrationDialogPayload | undefined>();

export const openSsRegistrationDialogUnsafe = createEvent<OpenRegistrationDialogPayload | undefined>();

export const closeSsRegistrationDialog = createEvent();

export const cancelSsRegistrationDialog = createEvent();

export const setRegistrationOrigin = createEvent<AuthOrigin>();

export const setSuccessVisible = createEvent();

export const resetDataState = createEvent();

const updateUserInternalFx = createClientEffect(updateUser);

export const updateUserFx = attach({
  effect: updateUserInternalFx,
  mapParams: (params: UserUpdatePayload, origin) => {
    return {...params, origin: origin.value};
  },
  source: $registrationOrigin,
});
