import {isSortingOrder} from 'lib/filtersAndSorting';
import {FilterType, FilterValue, MoneyRangeFilterValue} from 'lib/filtersAndSorting/types';
import {isArray, isNumber, isString} from 'lib/guards';
import {SearchParams} from './types';

/* Examples
/c.1473502935479416415-109-2-118-770440083
/f.brand.tree.5d09ef0236b54d00015518ba%2C5d0b47d528fc710001584bfe
/f.clr.black%2Cwhite%2Canimal%20print
/f.m.metal%2Cnylon%2Cfleece
/f.p.RUB~10-10000
/s.origPrice.asc
/q.браслет
 */

enum Entity {
  CATEGORY = 'c',
  FILTER = 'f',
  IMAGE = 'i',
  PHRASE = 'q',
  SEARCH_TAG = 't',
  SORTING = 's',
}

const SHORTCUT_BY_ID: Record<string, {shortcut: string; type: FilterType}> = {
  color: {
    shortcut: 'clr',
    type: FilterType.COLORS,
  },
  fastShipping: {
    shortcut: 'fsh',
    type: FilterType.CHECKBOX,
  },
  material: {
    shortcut: 'm',
    type: FilterType.TREE,
  },
  origPrice: {
    shortcut: 'p',
    type: FilterType.MONEY_RANGE,
  },
  searchTag: {
    shortcut: 'st',
    type: FilterType.SEARCH_TAG,
  },
};

type ShortcutData = Record<string, {id: string; type: FilterType}>;

const ID_BY_SHORTCUT: ShortcutData = Object.entries(SHORTCUT_BY_ID).reduce(
  (result: ShortcutData, [id, {type, shortcut}]) => {
    // eslint-disable-next-line no-param-reassign
    result[shortcut] = {id, type};
    return result;
  },
  {},
);

const CURRENCIES = ['USD', 'RUB'];

const PATH_SEPARATOR = '/';
const PARTS_SEPARATOR = '.';
const VALUE_SEPARATOR = ',';
const MONEY_RANGE_REGEX = /^([A-Z]{3})~(\d+(?:\.\d+)?)?-(\d+(?:\.\d+)?)?$/;

// type SimpleSearchParams = Pick<SearchParams, 'phrase' | 'categoryId' | 'filters' | 'sorting' | 'imageId'>;

function getShortcut(filter: FilterValue) {
  const item = SHORTCUT_BY_ID[filter.id];
  return item?.type === filter.type ? item.shortcut : undefined;
}

function getIdByShortcut(shortcut: string) {
  return ID_BY_SHORTCUT[shortcut];
}

function parseMoneyRangeValue(value: string): Pick<MoneyRangeFilterValue, 'currency' | 'min' | 'max'> | undefined {
  const match = value.match(MONEY_RANGE_REGEX);
  if (!match) {
    return undefined;
  }

  const [, rawCurrency, rawMin, rawMax] = match; // eslint-disable-line no-unused-vars
  const parsedMin = parseFloat(rawMin);
  const parsedMax = parseFloat(rawMax);
  const currency = rawCurrency.toUpperCase();
  let min: number | undefined;
  let max: number | undefined;

  if (rawMin) {
    if (Number.isNaN(parsedMin) || parsedMin < 0) {
      return undefined;
    }
    min = parsedMin;
  }

  if (rawMax) {
    if (Number.isNaN(parsedMax) || parsedMax <= 0) {
      return undefined;
    }
    max = parsedMax;
  }

  if (isNumber(min) && isNumber(max) && min > max) {
    return undefined;
  }

  if (min === undefined && max === undefined) {
    return undefined;
  }

  if (!CURRENCIES.includes(currency.toUpperCase())) {
    return undefined;
  }

  return {
    currency,
    max,
    min,
  };
}

export function parseSearchPath(path: unknown): SearchParams {
  const searchParams: SearchParams = {};
  let pathParts: unknown[];

  if (!path) {
    return searchParams;
  }

  if (isString(path)) {
    pathParts = path.split(PATH_SEPARATOR);
  } else if (isArray(path)) {
    pathParts = path;
  } else {
    return searchParams;
  }

  for (let i = 0; i < pathParts.length; i++) {
    const pathPart = pathParts[i];

    if (!isString(pathPart) || pathPart.length <= 2 || pathPart[1] !== PARTS_SEPARATOR) {
      // Skip incorrect path parts
      // eslint-disable-next-line no-continue
      continue;
    }

    const entityId = pathPart[0];
    const entityContent = pathPart.substring(2);

    switch (entityId) {
      case Entity.IMAGE: {
        searchParams.imageId = entityContent;
        break;
      }

      case Entity.CATEGORY: {
        searchParams.categoryId = entityContent;
        break;
      }

      case Entity.FILTER: {
        const firstDot = entityContent.indexOf(PARTS_SEPARATOR);
        let lastDot = firstDot;
        if (firstDot === -1) {
          break;
        }

        let id: string;
        let type: FilterType;
        const shortcut = entityContent.substring(0, firstDot);
        const shortcutData = getIdByShortcut(shortcut);

        if (shortcutData) {
          id = shortcutData.id;
          type = shortcutData.type;
        } else {
          id = shortcut;
          lastDot = entityContent.indexOf(PARTS_SEPARATOR, firstDot + 1);
          type = entityContent.substring(firstDot + 1, lastDot) as FilterType;
        }

        const value = entityContent.substring(lastDot + 1);

        if (!value) {
          break;
        }

        if (!searchParams.filters) {
          searchParams.filters = [];
        }

        switch (type) {
          case FilterType.CHECKBOX: {
            searchParams.filters.push({checked: value === 'on', id, type});
            break;
          }

          case FilterType.MONEY_RANGE: {
            const parsedValue = parseMoneyRangeValue(value);

            if (!parsedValue) {
              break;
            }

            searchParams.filters.push({id, type, ...parsedValue});

            break;
          }

          case FilterType.STORES:
          case FilterType.TREE:
          case FilterType.COLORS: {
            const items = value.split(VALUE_SEPARATOR).map((itemId) => ({id: itemId}));

            if (!items.length) {
              break;
            }

            searchParams.filters.push({id, items, type});
            break;
          }

          case FilterType.SEARCH_TAG: {
            const items = value.split(VALUE_SEPARATOR).map((itemId) => ({id: itemId}));
            searchParams.filters.push({id, items, type});
            break;
          }

          default:
          // do nothing
        }

        break;
      }

      case Entity.PHRASE: {
        searchParams.phrase = entityContent;
        break;
      }

      case Entity.SORTING: {
        const items = entityContent.split(PARTS_SEPARATOR);

        if (items.length === 2 && items[0] && isSortingOrder(items[1])) {
          if (!searchParams.sorting) {
            searchParams.sorting = [];
          }

          searchParams.sorting.push({
            fieldName: items[0],
            order: items[1],
          });
        }

        break;
      }

      default:
      // do nothing
    }
  }

  return searchParams;
}

export function buildSearchPath({phrase, categoryId, filters, sorting, imageId}: SearchParams): string {
  const resultPath = [];

  if (isArray(sorting) && sorting.length) {
    sorting.forEach(({fieldName, order}) => {
      resultPath.push(`${Entity.SORTING}.${fieldName}.${order}`);
    });
  }

  if (isArray(filters) && filters.length) {
    filters.forEach((filter) => {
      const shortcut = getShortcut(filter);
      const name = shortcut ? `${shortcut}` : `${filter.id}.${filter.type}`;

      let value;

      switch (filter.type) {
        case FilterType.SEARCH_TAG: {
          value = filter.items.map((item) => item.id).join(VALUE_SEPARATOR);
          break;
        }

        case FilterType.CHECKBOX: {
          value = filter.checked ? 'on' : 'off';
          break;
        }

        case FilterType.MONEY_RANGE: {
          const {currency, min, max} = filter;
          let valid = false;
          let normalizedMin = '';
          let normalizedMax = '';

          if (isNumber(min)) {
            if (min > 0) {
              normalizedMin = String(min);
              valid = true;
            }

            if (isNumber(max) && max >= min && max > 0) {
              normalizedMax = String(max);
              valid = true;
            }
          } else if (isNumber(max) && max > 0) {
            normalizedMax = String(max);
            valid = true;
          }

          if (valid) {
            value = `${currency || ''}~${normalizedMin}-${normalizedMax}`;
          }
          break;
        }

        case FilterType.TREE:
        case FilterType.COLORS: {
          value = filter.items.map((item) => item.id).join(VALUE_SEPARATOR);
          break;
        }

        default:
          return;
      }

      if (value !== undefined) {
        resultPath.push(`${Entity.FILTER}.${name}.${value}`);
      }
    });
  }

  if (categoryId) {
    resultPath.push(`${Entity.CATEGORY}.${categoryId}`);
  }

  if (phrase) {
    resultPath.push(`${Entity.PHRASE}.${phrase}`);
  }

  if (imageId) {
    resultPath.push(`${Entity.IMAGE}.${imageId}`);
  }

  return resultPath.map((part) => encodeURIComponent(part)).join(PATH_SEPARATOR);
}
