import {addInteraction} from 'api/interactions/addInteraction';
import {attach, Effect, Event, Store} from 'effector';
import {createClientEffect, createEvent, createStore} from 'lib/effector';
import {CommunicationState, CommunicationParams, CommunicationResponse, CommunicationOpenDialogParams} from './types';

type CommunicationStore = {
  $store: Store<CommunicationState>;
  checkDialog: Event<{pageUrl?: string; source?: string}>;
  closeDialog: Event<void>;
  openDialog: Event<CommunicationOpenDialogParams>;
  sendRequestFx: Effect<CommunicationParams, CommunicationResponse>;
};

export const globalSendFullCommunicationRequestFx = createClientEffect(addInteraction);

export function createCommunicationStore(sid: string): CommunicationStore {
  const initialState: CommunicationState = {opened: false, section: ''};
  const $store = createStore(sid, initialState);

  const openDialog = createEvent<CommunicationOpenDialogParams>();

  const checkDialog = createEvent<{pageUrl?: string; source?: string}>();

  const closeDialog = createEvent();

  const sendFullRequestFx = attach({effect: globalSendFullCommunicationRequestFx});

  const sendRequestFx = attach({
    effect: sendFullRequestFx,
    mapParams: (params: CommunicationParams, store) => {
      if (!store.reason) {
        throw new Error('Reason is not defined');
      }

      const {reason, type, source, campaign} = store;

      return {
        ...params,
        campaign,
        reason,
        source,
        type,
      };
    },
    source: $store,
  });

  $store
    .on(openDialog, (state, params) => ({
      ...state,
      campaign: params.campaign,
      opened: true,
      reason: params.reason,
      section: params.section,
      source: params.source,
      type: params.type,
    }))
    .on(sendFullRequestFx, (state, request) => ({
      ...state,
      error: undefined,
      processing: true,
      request,
      success: false,
    }))
    .on(sendFullRequestFx.doneData, (state, response) => ({
      ...state,
      lastReponse: response,
      processing: false,
      success: true,
    }))
    .on(sendFullRequestFx.failData, (state, error) => ({
      ...state,
      error,
      processing: false,
    }))
    .reset(closeDialog);

  return {
    $store,
    checkDialog,
    closeDialog,
    openDialog,
    sendRequestFx,
  };
}
