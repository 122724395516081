import { a as ln } from "../_commonjsHelpers-BVfed4GL.mjs";
var K, mr;
function sn() {
  return mr || (mr = 1, K = function() {
  }), K;
}
var H, dr;
function N() {
  if (dr) return H;
  dr = 1;
  var e = sn()();
  return H = function(r) {
    return r !== e && r !== null;
  }, H;
}
var L, gr;
function Xt() {
  if (gr) return L;
  gr = 1;
  var e = N(), r = Array.prototype.forEach, t = Object.create, l = function(f, o) {
    var u;
    for (u in f) o[u] = f[u];
  };
  return L = function(f) {
    var o = t(null);
    return r.call(arguments, function(u) {
      e(u) && l(Object(u), o);
    }), o;
  }, L;
}
var W, yr;
function cn() {
  return yr || (yr = 1, W = function() {
    var e = Math.sign;
    return typeof e != "function" ? !1 : e(10) === 1 && e(-20) === -1;
  }), W;
}
var B, br;
function pn() {
  return br || (br = 1, B = function(e) {
    return e = Number(e), isNaN(e) || e === 0 ? e : e > 0 ? 1 : -1;
  }), B;
}
var J, qr;
function hn() {
  return qr || (qr = 1, J = cn()() ? Math.sign : pn()), J;
}
var Z, Or;
function vn() {
  if (Or) return Z;
  Or = 1;
  var e = hn(), r = Math.abs, t = Math.floor;
  return Z = function(l) {
    return isNaN(l) ? 0 : (l = Number(l), l === 0 || !isFinite(l) ? l : e(l) * t(r(l)));
  }, Z;
}
var X, Sr;
function C() {
  if (Sr) return X;
  Sr = 1;
  var e = vn(), r = Math.max;
  return X = function(t) {
    return r(0, e(t));
  }, X;
}
var Y, Rr;
function Yt() {
  if (Rr) return Y;
  Rr = 1;
  var e = C();
  return Y = function(r, t, l) {
    var f;
    return isNaN(r) ? (f = t, f >= 0 ? l && f ? f - 1 : f : 1) : r === !1 ? !1 : e(r);
  }, Y;
}
var ee, Ir;
function E() {
  return Ir || (Ir = 1, ee = function(e) {
    if (typeof e != "function") throw new TypeError(e + " is not a function");
    return e;
  }), ee;
}
var re, xr;
function z() {
  if (xr) return re;
  xr = 1;
  var e = N();
  return re = function(r) {
    if (!e(r)) throw new TypeError("Cannot use null or undefined");
    return r;
  }, re;
}
var te, wr;
function mn() {
  if (wr) return te;
  wr = 1;
  var e = E(), r = z(), t = Function.prototype.bind, l = Function.prototype.call, f = Object.keys, o = Object.prototype.propertyIsEnumerable;
  return te = function(u, n) {
    return function(a, i) {
      var s, m = arguments[2], v = arguments[3];
      return a = Object(r(a)), e(i), s = f(a), v && s.sort(typeof v == "function" ? t.call(v, a) : void 0), typeof u != "function" && (u = s[u]), l.call(u, s, function(d, q) {
        return o.call(a, d) ? l.call(i, m, a[d], d, a, q) : n;
      });
    };
  }, te;
}
var ne, _r;
function Q() {
  return _r || (_r = 1, ne = mn()("forEach")), ne;
}
var Pr = {}, Tr;
function $() {
  return Tr || (Tr = 1), Pr;
}
var ie = { exports: {} }, ue, jr;
function dn() {
  return jr || (jr = 1, ue = function() {
    var e = Object.assign, r;
    return typeof e != "function" ? !1 : (r = { foo: "raz" }, e(r, { bar: "dwa" }, { trzy: "trzy" }), r.foo + r.bar + r.trzy === "razdwatrzy");
  }), ue;
}
var ae, Er;
function gn() {
  return Er || (Er = 1, ae = function() {
    try {
      return Object.keys("primitive"), !0;
    } catch {
      return !1;
    }
  }), ae;
}
var oe, Nr;
function yn() {
  if (Nr) return oe;
  Nr = 1;
  var e = N(), r = Object.keys;
  return oe = function(t) {
    return r(e(t) ? Object(t) : t);
  }, oe;
}
var fe, Cr;
function bn() {
  return Cr || (Cr = 1, fe = gn()() ? Object.keys : yn()), fe;
}
var le, $r;
function qn() {
  if ($r) return le;
  $r = 1;
  var e = bn(), r = z(), t = Math.max;
  return le = function(l, f) {
    var o, u, n = t(arguments.length, 2), a;
    for (l = Object(r(l)), a = function(i) {
      try {
        l[i] = f[i];
      } catch (s) {
        o || (o = s);
      }
    }, u = 1; u < n; ++u)
      f = arguments[u], e(f).forEach(a);
    if (o !== void 0) throw o;
    return l;
  }, le;
}
var se, Ar;
function en() {
  return Ar || (Ar = 1, se = dn()() ? Object.assign : qn()), se;
}
var ce, Fr;
function On() {
  if (Fr) return ce;
  Fr = 1;
  var e = N(), r = { function: !0, object: !0 };
  return ce = function(t) {
    return e(t) && r[typeof t] || !1;
  }, ce;
}
var zr;
function Sn() {
  return zr || (zr = 1, function(e) {
    var r = en(), t = On(), l = N(), f = Error.captureStackTrace;
    e.exports = function(o) {
      var u = new Error(o), n = arguments[1], a = arguments[2];
      return l(a) || t(n) && (a = n, n = null), l(a) && r(u, a), l(n) && (u.code = n), f && f(u, e.exports), u;
    };
  }(ie)), ie.exports;
}
var G = { exports: {} }, pe, Mr;
function rn() {
  if (Mr) return pe;
  Mr = 1;
  var e = z(), r = Object.defineProperty, t = Object.getOwnPropertyDescriptor, l = Object.getOwnPropertyNames, f = Object.getOwnPropertySymbols;
  return pe = function(o, u) {
    var n, a = Object(e(u));
    if (o = Object(e(o)), l(a).forEach(function(i) {
      try {
        r(o, i, t(u, i));
      } catch (s) {
        n = s;
      }
    }), typeof f == "function" && f(a).forEach(function(i) {
      try {
        r(o, i, t(u, i));
      } catch (s) {
        n = s;
      }
    }), n !== void 0) throw n;
    return o;
  }, pe;
}
var Vr;
function tn() {
  if (Vr) return G.exports;
  Vr = 1;
  var e = C(), r = function(u, n) {
    return n;
  }, t, l, f, o;
  try {
    Object.defineProperty(r, "length", {
      configurable: !0,
      writable: !1,
      enumerable: !1,
      value: 1
    });
  } catch {
  }
  return r.length === 1 ? (t = { configurable: !0, writable: !1, enumerable: !1 }, l = Object.defineProperty, G.exports = function(u, n) {
    return n = e(n), u.length === n ? u : (t.value = n, l(u, "length", t));
  }) : (o = rn(), f = /* @__PURE__ */ function() {
    var u = [];
    return function(n) {
      var a, i = 0;
      if (u[n]) return u[n];
      for (a = []; n--; ) a.push("a" + (++i).toString(36));
      return new Function(
        "fn",
        "return function (" + a.join(", ") + ") { return fn.apply(this, arguments); };"
      );
    };
  }(), G.exports = function(u, n) {
    var a;
    if (n = e(n), u.length === n) return u;
    a = f(n)(u);
    try {
      o(a, u);
    } catch {
    }
    return a;
  }), G.exports;
}
var he = { exports: {} }, ve, Gr;
function nn() {
  if (Gr) return ve;
  Gr = 1;
  var e = void 0;
  return ve = function(r) {
    return r !== e && r !== null;
  }, ve;
}
var me, Dr;
function Rn() {
  if (Dr) return me;
  Dr = 1;
  var e = nn(), r = {
    object: !0,
    function: !0,
    undefined: !0
    /* document.all */
  };
  return me = function(t) {
    return e(t) ? hasOwnProperty.call(r, typeof t) : !1;
  }, me;
}
var de, kr;
function In() {
  if (kr) return de;
  kr = 1;
  var e = Rn();
  return de = function(r) {
    if (!e(r)) return !1;
    try {
      return r.constructor ? r.constructor.prototype === r : !1;
    } catch {
      return !1;
    }
  }, de;
}
var ge, Qr;
function xn() {
  if (Qr) return ge;
  Qr = 1;
  var e = In();
  return ge = function(r) {
    if (typeof r != "function" || !hasOwnProperty.call(r, "length")) return !1;
    try {
      if (typeof r.length != "number" || typeof r.call != "function" || typeof r.apply != "function") return !1;
    } catch {
      return !1;
    }
    return !e(r);
  }, ge;
}
var ye, Ur;
function wn() {
  if (Ur) return ye;
  Ur = 1;
  var e = xn(), r = /^\s*class[\s{/}]/, t = Function.prototype.toString;
  return ye = function(l) {
    return !(!e(l) || r.test(t.call(l)));
  }, ye;
}
var be, Kr;
function _n() {
  if (Kr) return be;
  Kr = 1;
  var e = "razdwatrzy";
  return be = function() {
    return typeof e.contains != "function" ? !1 : e.contains("dwa") === !0 && e.contains("foo") === !1;
  }, be;
}
var qe, Hr;
function Pn() {
  if (Hr) return qe;
  Hr = 1;
  var e = String.prototype.indexOf;
  return qe = function(r) {
    return e.call(this, r, arguments[1]) > -1;
  }, qe;
}
var Oe, Lr;
function Tn() {
  return Lr || (Lr = 1, Oe = _n()() ? String.prototype.contains : Pn()), Oe;
}
var Wr;
function A() {
  if (Wr) return he.exports;
  Wr = 1;
  var e = nn(), r = wn(), t = en(), l = Xt(), f = Tn(), o = he.exports = function(u, n) {
    var a, i, s, m, v;
    return arguments.length < 2 || typeof u != "string" ? (m = n, n = u, u = null) : m = arguments[2], e(u) ? (a = f.call(u, "c"), i = f.call(u, "e"), s = f.call(u, "w")) : (a = s = !0, i = !1), v = { value: n, configurable: a, enumerable: i, writable: s }, m ? t(l(m), v) : v;
  };
  return o.gs = function(u, n, a) {
    var i, s, m, v;
    return typeof u != "string" ? (m = a, a = n, n = u, u = null) : m = arguments[3], e(n) ? r(n) ? e(a) ? r(a) || (m = a, a = void 0) : a = void 0 : (m = n, n = a = void 0) : n = void 0, e(u) ? (i = f.call(u, "c"), s = f.call(u, "e")) : (i = !0, s = !1), v = { get: n, set: a, configurable: i, enumerable: s }, m ? t(l(m), v) : v;
  }, he.exports;
}
var D = { exports: {} }, Br;
function jn() {
  return Br || (Br = 1, function(e, r) {
    var t = A(), l = E(), f = Function.prototype.apply, o = Function.prototype.call, u = Object.create, n = Object.defineProperty, a = Object.defineProperties, i = Object.prototype.hasOwnProperty, s = { configurable: !0, enumerable: !1, writable: !0 }, m, v, d, q, y, p, c;
    m = function(h, g) {
      var b;
      return l(g), i.call(this, "__ee__") ? b = this.__ee__ : (b = s.value = u(null), n(this, "__ee__", s), s.value = null), b[h] ? typeof b[h] == "object" ? b[h].push(g) : b[h] = [b[h], g] : b[h] = g, this;
    }, v = function(h, g) {
      var b, R;
      return l(g), R = this, m.call(this, h, b = function() {
        d.call(R, h, b), f.call(g, this, arguments);
      }), b.__eeOnceListener__ = g, this;
    }, d = function(h, g) {
      var b, R, I, x;
      if (l(g), !i.call(this, "__ee__")) return this;
      if (b = this.__ee__, !b[h]) return this;
      if (R = b[h], typeof R == "object")
        for (x = 0; I = R[x]; ++x)
          (I === g || I.__eeOnceListener__ === g) && (R.length === 2 ? b[h] = R[x ? 0 : 1] : R.splice(x, 1));
      else
        (R === g || R.__eeOnceListener__ === g) && delete b[h];
      return this;
    }, q = function(h) {
      var g, b, R, I, x;
      if (i.call(this, "__ee__") && (I = this.__ee__[h], !!I))
        if (typeof I == "object") {
          for (b = arguments.length, x = new Array(b - 1), g = 1; g < b; ++g) x[g - 1] = arguments[g];
          for (I = I.slice(), g = 0; R = I[g]; ++g)
            f.call(R, this, x);
        } else
          switch (arguments.length) {
            case 1:
              o.call(I, this);
              break;
            case 2:
              o.call(I, this, arguments[1]);
              break;
            case 3:
              o.call(I, this, arguments[1], arguments[2]);
              break;
            default:
              for (b = arguments.length, x = new Array(b - 1), g = 1; g < b; ++g)
                x[g - 1] = arguments[g];
              f.call(I, this, x);
          }
    }, y = {
      on: m,
      once: v,
      off: d,
      emit: q
    }, p = {
      on: t(m),
      once: t(v),
      off: t(d),
      emit: t(q)
    }, c = a({}, p), e.exports = r = function(h) {
      return h == null ? u(c) : a(Object(h), p);
    }, r.methods = y;
  }(D, D.exports)), D.exports;
}
var Se, Jr;
function En() {
  return Jr || (Jr = 1, Se = function() {
    var e = Array.from, r, t;
    return typeof e != "function" ? !1 : (r = ["raz", "dwa"], t = e(r), !!(t && t !== r && t[1] === "dwa"));
  }), Se;
}
var Re, Zr;
function Nn() {
  return Zr || (Zr = 1, Re = function() {
    return typeof globalThis != "object" || !globalThis ? !1 : globalThis.Array === Array;
  }), Re;
}
var Ie, Xr;
function Cn() {
  if (Xr) return Ie;
  Xr = 1;
  var e = function() {
    if (typeof self == "object" && self) return self;
    if (typeof window == "object" && window) return window;
    throw new Error("Unable to resolve global `this`");
  };
  return Ie = function() {
    if (this) return this;
    try {
      Object.defineProperty(Object.prototype, "__global__", {
        get: function() {
          return this;
        },
        configurable: !0
      });
    } catch {
      return e();
    }
    try {
      return __global__ || e();
    } finally {
      delete Object.prototype.__global__;
    }
  }(), Ie;
}
var xe, Yr;
function U() {
  return Yr || (Yr = 1, xe = Nn()() ? globalThis : Cn()), xe;
}
var we, et;
function $n() {
  if (et) return we;
  et = 1;
  var e = U(), r = { object: !0, symbol: !0 };
  return we = function() {
    var t = e.Symbol, l;
    if (typeof t != "function") return !1;
    l = t("test symbol");
    try {
      String(l);
    } catch {
      return !1;
    }
    return !(!r[typeof t.iterator] || !r[typeof t.toPrimitive] || !r[typeof t.toStringTag]);
  }, we;
}
var _e, rt;
function An() {
  return rt || (rt = 1, _e = function(e) {
    return e ? typeof e == "symbol" ? !0 : !e.constructor || e.constructor.name !== "Symbol" ? !1 : e[e.constructor.toStringTag] === "Symbol" : !1;
  }), _e;
}
var Pe, tt;
function un() {
  if (tt) return Pe;
  tt = 1;
  var e = An();
  return Pe = function(r) {
    if (!e(r)) throw new TypeError(r + " is not a symbol");
    return r;
  }, Pe;
}
var Te, nt;
function Fn() {
  if (nt) return Te;
  nt = 1;
  var e = A(), r = Object.create, t = Object.defineProperty, l = Object.prototype, f = r(null);
  return Te = function(o) {
    for (var u = 0, n, a; f[o + (u || "")]; ) ++u;
    return o += u || "", f[o] = !0, n = "@@" + o, t(
      l,
      n,
      e.gs(null, function(i) {
        a || (a = !0, t(this, n, e(i)), a = !1);
      })
    ), n;
  }, Te;
}
var je, it;
function zn() {
  if (it) return je;
  it = 1;
  var e = A(), r = U().Symbol;
  return je = function(t) {
    return Object.defineProperties(t, {
      // To ensure proper interoperability with other native functions (e.g. Array.from)
      // fallback to eventual native implementation of given symbol
      hasInstance: e(
        "",
        r && r.hasInstance || t("hasInstance")
      ),
      isConcatSpreadable: e(
        "",
        r && r.isConcatSpreadable || t("isConcatSpreadable")
      ),
      iterator: e("", r && r.iterator || t("iterator")),
      match: e("", r && r.match || t("match")),
      replace: e("", r && r.replace || t("replace")),
      search: e("", r && r.search || t("search")),
      species: e("", r && r.species || t("species")),
      split: e("", r && r.split || t("split")),
      toPrimitive: e(
        "",
        r && r.toPrimitive || t("toPrimitive")
      ),
      toStringTag: e(
        "",
        r && r.toStringTag || t("toStringTag")
      ),
      unscopables: e(
        "",
        r && r.unscopables || t("unscopables")
      )
    });
  }, je;
}
var Ee, ut;
function Mn() {
  if (ut) return Ee;
  ut = 1;
  var e = A(), r = un(), t = /* @__PURE__ */ Object.create(null);
  return Ee = function(l) {
    return Object.defineProperties(l, {
      for: e(function(f) {
        return t[f] ? t[f] : t[f] = l(String(f));
      }),
      keyFor: e(function(f) {
        var o;
        r(f);
        for (o in t)
          if (t[o] === f) return o;
      })
    });
  }, Ee;
}
var Ne, at;
function Vn() {
  if (at) return Ne;
  at = 1;
  var e = A(), r = un(), t = U().Symbol, l = Fn(), f = zn(), o = Mn(), u = Object.create, n = Object.defineProperties, a = Object.defineProperty, i, s, m;
  if (typeof t == "function")
    try {
      String(t()), m = !0;
    } catch {
    }
  else
    t = null;
  return s = function(d) {
    if (this instanceof s) throw new TypeError("Symbol is not a constructor");
    return i(d);
  }, Ne = i = function v(d) {
    var q;
    if (this instanceof v) throw new TypeError("Symbol is not a constructor");
    return m ? t(d) : (q = u(s.prototype), d = d === void 0 ? "" : String(d), n(q, {
      __description__: e("", d),
      __name__: e("", l(d))
    }));
  }, f(i), o(i), n(s.prototype, {
    constructor: e(i),
    toString: e("", function() {
      return this.__name__;
    })
  }), n(i.prototype, {
    toString: e(function() {
      return "Symbol (" + r(this).__description__ + ")";
    }),
    valueOf: e(function() {
      return r(this);
    })
  }), a(
    i.prototype,
    i.toPrimitive,
    e("", function() {
      var v = r(this);
      return typeof v == "symbol" ? v : v.toString();
    })
  ), a(i.prototype, i.toStringTag, e("c", "Symbol")), a(
    s.prototype,
    i.toStringTag,
    e("c", i.prototype[i.toStringTag])
  ), a(
    s.prototype,
    i.toPrimitive,
    e("c", i.prototype[i.toPrimitive])
  ), Ne;
}
var Ce, ot;
function Gn() {
  return ot || (ot = 1, Ce = $n()() ? U().Symbol : Vn()), Ce;
}
var $e, ft;
function Dn() {
  if (ft) return $e;
  ft = 1;
  var e = Object.prototype.toString, r = e.call(/* @__PURE__ */ function() {
    return arguments;
  }());
  return $e = function(t) {
    return e.call(t) === r;
  }, $e;
}
var Ae, lt;
function kn() {
  if (lt) return Ae;
  lt = 1;
  var e = Object.prototype.toString, r = RegExp.prototype.test.bind(/^[object [A-Za-z0-9]*Function]$/);
  return Ae = function(t) {
    return typeof t == "function" && r(e.call(t));
  }, Ae;
}
var Fe, st;
function Qn() {
  if (st) return Fe;
  st = 1;
  var e = Object.prototype.toString, r = e.call("");
  return Fe = function(t) {
    return typeof t == "string" || t && typeof t == "object" && (t instanceof String || e.call(t) === r) || !1;
  }, Fe;
}
var ze, ct;
function Un() {
  if (ct) return ze;
  ct = 1;
  var e = Gn().iterator, r = Dn(), t = kn(), l = C(), f = E(), o = z(), u = N(), n = Qn(), a = Array.isArray, i = Function.prototype.call, s = { configurable: !0, enumerable: !0, writable: !0, value: null }, m = Object.defineProperty;
  return ze = function(v) {
    var d = arguments[1], q = arguments[2], y, p, c, h, g, b, R, I, x, w;
    if (v = Object(o(v)), u(d) && f(d), !this || this === Array || !t(this)) {
      if (!d) {
        if (r(v))
          return g = v.length, g !== 1 ? Array.apply(null, v) : (h = new Array(1), h[0] = v[0], h);
        if (a(v)) {
          for (h = new Array(g = v.length), p = 0; p < g; ++p) h[p] = v[p];
          return h;
        }
      }
      h = [];
    } else
      y = this;
    if (!a(v)) {
      if ((x = v[e]) !== void 0) {
        for (R = f(x).call(v), y && (h = new y()), I = R.next(), p = 0; !I.done; )
          w = d ? i.call(d, q, I.value, p) : I.value, y ? (s.value = w, m(h, p, s)) : h[p] = w, I = R.next(), ++p;
        g = p;
      } else if (n(v)) {
        for (g = v.length, y && (h = new y()), p = 0, c = 0; p < g; ++p)
          w = v[p], p + 1 < g && (b = w.charCodeAt(0), b >= 55296 && b <= 56319 && (w += v[++p])), w = d ? i.call(d, q, w, c) : w, y ? (s.value = w, m(h, c, s)) : h[c] = w, ++c;
        g = c;
      }
    }
    if (g === void 0)
      for (g = l(v.length), y && (h = new y(g)), p = 0; p < g; ++p)
        w = d ? i.call(d, q, v[p], p) : v[p], y ? (s.value = w, m(h, p, s)) : h[p] = w;
    return y && (s.value = null, h.length = g), h;
  }, ze;
}
var Me, pt;
function cr() {
  return pt || (pt = 1, Me = En()() ? Array.from : Un()), Me;
}
var Ve, ht;
function Kn() {
  if (ht) return Ve;
  ht = 1;
  var e = cr(), r = Array.isArray;
  return Ve = function(t) {
    return r(t) ? t : e(t);
  }, Ve;
}
var Ge, vt;
function Hn() {
  if (vt) return Ge;
  vt = 1;
  var e = Kn(), r = N(), t = E(), l = Array.prototype.slice, f;
  return f = function(o) {
    return this.map(function(u, n) {
      return u ? u(o[n]) : o[n];
    }).concat(
      l.call(o, this.length)
    );
  }, Ge = function(o) {
    return o = e(o), o.forEach(function(u) {
      r(u) && t(u);
    }), f.bind(o);
  }, Ge;
}
var De, mt;
function Ln() {
  if (mt) return De;
  mt = 1;
  var e = E();
  return De = function(r) {
    var t;
    return typeof r == "function" ? { set: r, get: r } : (t = { get: e(r.get) }, r.set !== void 0 ? (t.set = e(r.set), r.delete && (t.delete = e(r.delete)), r.clear && (t.clear = e(r.clear)), t) : (t.set = t.get, t));
  }, De;
}
var ke, dt;
function Wn() {
  if (dt) return ke;
  dt = 1;
  var e = Sn(), r = tn(), t = A(), l = jn().methods, f = Hn(), o = Ln(), u = Function.prototype.apply, n = Function.prototype.call, a = Object.create, i = Object.defineProperties, s = l.on, m = l.emit;
  return ke = function(v, d, q) {
    var y = a(null), p, c, h, g, b, R, I, x, w, j, F, V, vr, M, T;
    return d !== !1 ? c = d : isNaN(v.length) ? c = 1 : c = v.length, q.normalizer && (j = o(q.normalizer), h = j.get, g = j.set, b = j.delete, R = j.clear), q.resolvers != null && (T = f(q.resolvers)), h ? M = r(function(O) {
      var S, P, _ = arguments;
      if (T && (_ = T(_)), S = h(_), S !== null && hasOwnProperty.call(y, S))
        return F && p.emit("get", S, _, this), y[S];
      if (_.length === 1 ? P = n.call(v, this, _[0]) : P = u.call(v, this, _), S === null) {
        if (S = h(_), S !== null) throw e("Circular invocation", "CIRCULAR_INVOCATION");
        S = g(_);
      } else if (hasOwnProperty.call(y, S))
        throw e("Circular invocation", "CIRCULAR_INVOCATION");
      return y[S] = P, V && p.emit("set", S, null, P), P;
    }, c) : d === 0 ? M = function() {
      var O;
      if (hasOwnProperty.call(y, "data"))
        return F && p.emit("get", "data", arguments, this), y.data;
      if (arguments.length ? O = u.call(v, this, arguments) : O = n.call(v, this), hasOwnProperty.call(y, "data"))
        throw e("Circular invocation", "CIRCULAR_INVOCATION");
      return y.data = O, V && p.emit("set", "data", null, O), O;
    } : M = function(O) {
      var S, P = arguments, _;
      if (T && (P = T(arguments)), _ = String(P[0]), hasOwnProperty.call(y, _))
        return F && p.emit("get", _, P, this), y[_];
      if (P.length === 1 ? S = n.call(v, this, P[0]) : S = u.call(v, this, P), hasOwnProperty.call(y, _))
        throw e("Circular invocation", "CIRCULAR_INVOCATION");
      return y[_] = S, V && p.emit("set", _, null, S), S;
    }, p = {
      original: v,
      memoized: M,
      profileName: q.profileName,
      get: function(O) {
        return T && (O = T(O)), h ? h(O) : String(O[0]);
      },
      has: function(O) {
        return hasOwnProperty.call(y, O);
      },
      delete: function(O) {
        var S;
        hasOwnProperty.call(y, O) && (b && b(O), S = y[O], delete y[O], vr && p.emit("delete", O, S));
      },
      clear: function() {
        var O = y;
        R && R(), y = a(null), p.emit("clear", O);
      },
      on: function(O, S) {
        return O === "get" ? F = !0 : O === "set" ? V = !0 : O === "delete" && (vr = !0), s.call(this, O, S);
      },
      emit: m,
      updateEnv: function() {
        v = p.original;
      }
    }, h ? I = r(function(O) {
      var S, P = arguments;
      T && (P = T(P)), S = h(P), S !== null && p.delete(S);
    }, c) : d === 0 ? I = function() {
      return p.delete("data");
    } : I = function(O) {
      return T && (O = T(arguments)[0]), p.delete(O);
    }, x = r(function() {
      var O, S = arguments;
      return d === 0 ? y.data : (T && (S = T(S)), h ? O = h(S) : O = String(S[0]), y[O]);
    }), w = r(function() {
      var O, S = arguments;
      return d === 0 ? p.has("data") : (T && (S = T(S)), h ? O = h(S) : O = String(S[0]), O === null ? !1 : p.has(O));
    }), i(M, {
      __memoized__: t(!0),
      delete: t(I),
      clear: t(p.clear),
      _get: t(x),
      _has: t(w)
    }), p;
  }, ke;
}
var Qe, gt;
function Bn() {
  if (gt) return Qe;
  gt = 1;
  var e = E(), r = Q(), t = $(), l = Wn(), f = Yt();
  return Qe = function o(u) {
    var n, a, i;
    if (e(u), n = Object(arguments[1]), n.async && n.promise)
      throw new Error("Options 'async' and 'promise' cannot be used together");
    return hasOwnProperty.call(u, "__memoized__") && !n.force ? u : (a = f(n.length, u.length, n.async && t.async), i = l(u, a, n), r(t, function(s, m) {
      n[m] && s(n[m], i, n);
    }), o.__profiler__ && o.__profiler__(i), i.updateEnv(), i.memoized);
  }, Qe;
}
var Ue, yt;
function Jn() {
  return yt || (yt = 1, Ue = function(e) {
    var r, t, l = e.length;
    if (!l) return "";
    for (r = String(e[t = 0]); --l; ) r += "" + e[++t];
    return r;
  }), Ue;
}
var Ke, bt;
function Zn() {
  return bt || (bt = 1, Ke = function(e) {
    return e ? function(r) {
      for (var t = String(r[0]), l = 0, f = e; --f; )
        t += "" + r[++l];
      return t;
    } : function() {
      return "";
    };
  }), Ke;
}
var He, qt;
function Xn() {
  return qt || (qt = 1, He = function() {
    var e = Number.isNaN;
    return typeof e != "function" ? !1 : !e({}) && e(NaN) && !e(34);
  }), He;
}
var Le, Ot;
function Yn() {
  return Ot || (Ot = 1, Le = function(e) {
    return e !== e;
  }), Le;
}
var We, St;
function ei() {
  return St || (St = 1, We = Xn()() ? Number.isNaN : Yn()), We;
}
var Be, Rt;
function pr() {
  if (Rt) return Be;
  Rt = 1;
  var e = ei(), r = C(), t = z(), l = Array.prototype.indexOf, f = Object.prototype.hasOwnProperty, o = Math.abs, u = Math.floor;
  return Be = function(n) {
    var a, i, s, m;
    if (!e(n)) return l.apply(this, arguments);
    for (i = r(t(this).length), s = arguments[1], isNaN(s) ? s = 0 : s >= 0 ? s = u(s) : s = r(this.length) - u(o(s)), a = s; a < i; ++a)
      if (f.call(this, a) && (m = this[a], e(m)))
        return a;
    return -1;
  }, Be;
}
var Je, It;
function ri() {
  if (It) return Je;
  It = 1;
  var e = pr(), r = Object.create;
  return Je = function() {
    var t = 0, l = [], f = r(null);
    return {
      get: function(o) {
        var u = 0, n = l, a, i = o.length;
        if (i === 0) return n[i] || null;
        if (n = n[i]) {
          for (; u < i - 1; ) {
            if (a = e.call(n[0], o[u]), a === -1) return null;
            n = n[1][a], ++u;
          }
          return a = e.call(n[0], o[u]), a === -1 ? null : n[1][a] || null;
        }
        return null;
      },
      set: function(o) {
        var u = 0, n = l, a, i = o.length;
        if (i === 0)
          n[i] = ++t;
        else {
          for (n[i] || (n[i] = [[], []]), n = n[i]; u < i - 1; )
            a = e.call(n[0], o[u]), a === -1 && (a = n[0].push(o[u]) - 1, n[1].push([[], []])), n = n[1][a], ++u;
          a = e.call(n[0], o[u]), a === -1 && (a = n[0].push(o[u]) - 1), n[1][a] = ++t;
        }
        return f[t] = o, t;
      },
      delete: function(o) {
        var u = 0, n = l, a, i = f[o], s = i.length, m = [];
        if (s === 0)
          delete n[s];
        else if (n = n[s]) {
          for (; u < s - 1; ) {
            if (a = e.call(n[0], i[u]), a === -1)
              return;
            m.push(n, a), n = n[1][a], ++u;
          }
          if (a = e.call(n[0], i[u]), a === -1)
            return;
          for (o = n[1][a], n[0].splice(a, 1), n[1].splice(a, 1); !n[0].length && m.length; )
            a = m.pop(), n = m.pop(), n[0].splice(a, 1), n[1].splice(a, 1);
        }
        delete f[o];
      },
      clear: function() {
        l = [], f = r(null);
      }
    };
  }, Je;
}
var Ze, xt;
function ti() {
  if (xt) return Ze;
  xt = 1;
  var e = pr();
  return Ze = function() {
    var r = 0, t = [], l = [];
    return {
      get: function(f) {
        var o = e.call(t, f[0]);
        return o === -1 ? null : l[o];
      },
      set: function(f) {
        return t.push(f[0]), l.push(++r), r;
      },
      delete: function(f) {
        var o = e.call(l, f);
        o !== -1 && (t.splice(o, 1), l.splice(o, 1));
      },
      clear: function() {
        t = [], l = [];
      }
    };
  }, Ze;
}
var Xe, wt;
function ni() {
  if (wt) return Xe;
  wt = 1;
  var e = pr(), r = Object.create;
  return Xe = function(t) {
    var l = 0, f = [[], []], o = r(null);
    return {
      get: function(u) {
        for (var n = 0, a = f, i; n < t - 1; ) {
          if (i = e.call(a[0], u[n]), i === -1) return null;
          a = a[1][i], ++n;
        }
        return i = e.call(a[0], u[n]), i === -1 ? null : a[1][i] || null;
      },
      set: function(u) {
        for (var n = 0, a = f, i; n < t - 1; )
          i = e.call(a[0], u[n]), i === -1 && (i = a[0].push(u[n]) - 1, a[1].push([[], []])), a = a[1][i], ++n;
        return i = e.call(a[0], u[n]), i === -1 && (i = a[0].push(u[n]) - 1), a[1][i] = ++l, o[l] = u, l;
      },
      delete: function(u) {
        for (var n = 0, a = f, i, s = [], m = o[u]; n < t - 1; ) {
          if (i = e.call(a[0], m[n]), i === -1)
            return;
          s.push(a, i), a = a[1][i], ++n;
        }
        if (i = e.call(a[0], m[n]), i !== -1) {
          for (u = a[1][i], a[0].splice(i, 1), a[1].splice(i, 1); !a[0].length && s.length; )
            i = s.pop(), a = s.pop(), a[0].splice(i, 1), a[1].splice(i, 1);
          delete o[u];
        }
      },
      clear: function() {
        f = [[], []], o = r(null);
      }
    };
  }, Xe;
}
var _t = {}, Ye, Pt;
function an() {
  if (Pt) return Ye;
  Pt = 1;
  var e = E(), r = Q(), t = Function.prototype.call;
  return Ye = function(l, f) {
    var o = {}, u = arguments[2];
    return e(f), r(l, function(n, a, i, s) {
      o[a] = t.call(f, u, n, a, i, s);
    }), o;
  }, Ye;
}
var er, Tt;
function hr() {
  if (Tt) return er;
  Tt = 1;
  var e = function(t) {
    if (typeof t != "function") throw new TypeError(t + " is not a function");
    return t;
  }, r = function(t) {
    var l = document.createTextNode(""), f, o, u = 0;
    return new t(function() {
      var n;
      if (f)
        o && (f = o.concat(f));
      else {
        if (!o) return;
        f = o;
      }
      if (o = f, f = null, typeof o == "function") {
        n = o, o = null, n();
        return;
      }
      for (l.data = u = ++u % 2; o; )
        n = o.shift(), o.length || (o = null), n();
    }).observe(l, { characterData: !0 }), function(n) {
      if (e(n), f) {
        typeof f == "function" ? f = [f, n] : f.push(n);
        return;
      }
      f = n, l.data = u = ++u % 2;
    };
  };
  return er = function() {
    if (typeof process == "object" && process && typeof process.nextTick == "function")
      return process.nextTick;
    if (typeof queueMicrotask == "function")
      return function(t) {
        queueMicrotask(e(t));
      };
    if (typeof document == "object" && document) {
      if (typeof MutationObserver == "function") return r(MutationObserver);
      if (typeof WebKitMutationObserver == "function") return r(WebKitMutationObserver);
    }
    return typeof setImmediate == "function" ? function(t) {
      setImmediate(e(t));
    } : typeof setTimeout == "function" || typeof setTimeout == "object" ? function(t) {
      setTimeout(e(t), 0);
    } : null;
  }(), er;
}
var jt;
function ii() {
  if (jt) return _t;
  jt = 1;
  var e = cr(), r = an(), t = rn(), l = tn(), f = hr(), o = Array.prototype.slice, u = Function.prototype.apply, n = Object.create;
  return $().async = function(a, i) {
    var s = n(null), m = n(null), v = i.memoized, d = i.original, q, y, p;
    i.memoized = l(function(c) {
      var h = arguments, g = h[h.length - 1];
      return typeof g == "function" && (q = g, h = o.call(h, 0, -1)), v.apply(y = this, p = h);
    }, v);
    try {
      t(i.memoized, v);
    } catch {
    }
    i.on("get", function(c) {
      var h, g, b;
      if (q) {
        if (s[c]) {
          typeof s[c] == "function" ? s[c] = [s[c], q] : s[c].push(q), q = null;
          return;
        }
        h = q, g = y, b = p, q = y = p = null, f(function() {
          var R;
          hasOwnProperty.call(m, c) ? (R = m[c], i.emit("getasync", c, b, g), u.call(h, R.context, R.args)) : (q = h, y = g, p = b, v.apply(g, b));
        });
      }
    }), i.original = function() {
      var c, h, g, b;
      return q ? (c = e(arguments), h = function R(I) {
        var x, w, j = R.id;
        if (j == null) {
          f(u.bind(R, this, arguments));
          return;
        }
        if (delete R.id, x = s[j], delete s[j], !!x)
          return w = e(arguments), i.has(j) && (I ? i.delete(j) : (m[j] = { context: this, args: w }, i.emit("setasync", j, typeof x == "function" ? 1 : x.length))), typeof x == "function" ? b = u.call(x, this, w) : x.forEach(function(F) {
            b = u.call(F, this, w);
          }, this), b;
      }, g = q, q = y = p = null, c.push(h), b = u.call(d, this, c), h.cb = g, q = h, b) : u.call(d, this, arguments);
    }, i.on("set", function(c) {
      if (!q) {
        i.delete(c);
        return;
      }
      s[c] ? typeof s[c] == "function" ? s[c] = [s[c], q.cb] : s[c].push(q.cb) : s[c] = q.cb, delete q.cb, q.id = c, q = null;
    }), i.on("delete", function(c) {
      var h;
      hasOwnProperty.call(s, c) || m[c] && (h = m[c], delete m[c], i.emit("deleteasync", c, o.call(h.args, 1)));
    }), i.on("clear", function() {
      var c = m;
      m = n(null), i.emit(
        "clearasync",
        r(c, function(h) {
          return o.call(h.args, 1);
        })
      );
    });
  }, _t;
}
var Et = {}, rr, Nt;
function ui() {
  if (Nt) return rr;
  Nt = 1;
  var e = Array.prototype.forEach, r = Object.create;
  return rr = function(t) {
    var l = r(null);
    return e.call(arguments, function(f) {
      l[f] = !0;
    }), l;
  }, rr;
}
var tr, Ct;
function on() {
  return Ct || (Ct = 1, tr = function(e) {
    return typeof e == "function";
  }), tr;
}
var nr, $t;
function ai() {
  if ($t) return nr;
  $t = 1;
  var e = on();
  return nr = function(r) {
    try {
      return r && e(r.toString) ? r.toString() : String(r);
    } catch {
      throw new TypeError("Passed argument cannot be stringifed");
    }
  }, nr;
}
var ir, At;
function oi() {
  if (At) return ir;
  At = 1;
  var e = z(), r = ai();
  return ir = function(t) {
    return r(e(t));
  }, ir;
}
var ur, Ft;
function fi() {
  if (Ft) return ur;
  Ft = 1;
  var e = on();
  return ur = function(r) {
    try {
      return r && e(r.toString) ? r.toString() : String(r);
    } catch {
      return "<Non-coercible to string value>";
    }
  }, ur;
}
var ar, zt;
function li() {
  if (zt) return ar;
  zt = 1;
  var e = fi(), r = /[\n\r\u2028\u2029]/g;
  return ar = function(t) {
    var l = e(t);
    return l.length > 100 && (l = l.slice(0, 99) + "…"), l = l.replace(r, function(f) {
      return JSON.stringify(f).slice(1, -1);
    }), l;
  }, ar;
}
var k = { exports: {} }, Mt;
function fn() {
  if (Mt) return k.exports;
  Mt = 1, k.exports = e, k.exports.default = e;
  function e(r) {
    return !!r && (typeof r == "object" || typeof r == "function") && typeof r.then == "function";
  }
  return k.exports;
}
var Vt;
function si() {
  if (Vt) return Et;
  Vt = 1;
  var e = an(), r = ui(), t = oi(), l = li(), f = fn(), o = hr(), u = Object.create, n = r("then", "then:finally", "done", "done:finally");
  return $().promise = function(a, i) {
    var s = u(null), m = u(null), v = u(null);
    if (a === !0)
      a = null;
    else if (a = t(a), !n[a])
      throw new TypeError("'" + l(a) + "' is not valid promise mode");
    i.on("set", function(d, q, y) {
      var p = !1;
      if (!f(y)) {
        m[d] = y, i.emit("setasync", d, 1);
        return;
      }
      s[d] = 1, v[d] = y;
      var c = function(R) {
        var I = s[d];
        if (p)
          throw new Error(
            `Memoizee error: Detected unordered then|done & finally resolution, which in turn makes proper detection of success/failure impossible (when in 'done:finally' mode)
Consider to rely on 'then' or 'done' mode instead.`
          );
        I && (delete s[d], m[d] = R, i.emit("setasync", d, I));
      }, h = function() {
        p = !0, s[d] && (delete s[d], delete v[d], i.delete(d));
      }, g = a;
      if (g || (g = "then"), g === "then") {
        var b = function() {
          o(h);
        };
        y = y.then(function(R) {
          o(c.bind(this, R));
        }, b), typeof y.finally == "function" && y.finally(b);
      } else if (g === "done") {
        if (typeof y.done != "function")
          throw new Error(
            "Memoizee error: Retrieved promise does not implement 'done' in 'done' mode"
          );
        y.done(c, h);
      } else if (g === "done:finally") {
        if (typeof y.done != "function")
          throw new Error(
            "Memoizee error: Retrieved promise does not implement 'done' in 'done:finally' mode"
          );
        if (typeof y.finally != "function")
          throw new Error(
            "Memoizee error: Retrieved promise does not implement 'finally' in 'done:finally' mode"
          );
        y.done(c), y.finally(h);
      }
    }), i.on("get", function(d, q, y) {
      var p;
      if (s[d]) {
        ++s[d];
        return;
      }
      p = v[d];
      var c = function() {
        i.emit("getasync", d, q, y);
      };
      f(p) ? typeof p.done == "function" ? p.done(c) : p.then(function() {
        o(c);
      }) : c();
    }), i.on("delete", function(d) {
      if (delete v[d], s[d]) {
        delete s[d];
        return;
      }
      if (hasOwnProperty.call(m, d)) {
        var q = m[d];
        delete m[d], i.emit("deleteasync", d, [q]);
      }
    }), i.on("clear", function() {
      var d = m;
      m = u(null), s = u(null), v = u(null), i.emit("clearasync", e(d, function(q) {
        return [q];
      }));
    });
  }, Et;
}
var Gt = {}, Dt;
function ci() {
  if (Dt) return Gt;
  Dt = 1;
  var e = E(), r = Q(), t = $(), l = Function.prototype.apply;
  return t.dispose = function(f, o, u) {
    var n;
    if (e(f), u.async && t.async || u.promise && t.promise) {
      o.on(
        "deleteasync",
        n = function(a, i) {
          l.call(f, null, i);
        }
      ), o.on("clearasync", function(a) {
        r(a, function(i, s) {
          n(s, i);
        });
      });
      return;
    }
    o.on("delete", n = function(a, i) {
      f(i);
    }), o.on("clear", function(a) {
      r(a, function(i, s) {
        n(s, i);
      });
    });
  }, Gt;
}
var kt = {}, or, Qt;
function pi() {
  return Qt || (Qt = 1, or = 2147483647), or;
}
var fr, Ut;
function hi() {
  if (Ut) return fr;
  Ut = 1;
  var e = C(), r = pi();
  return fr = function(t) {
    if (t = e(t), t > r) throw new TypeError(t + " exceeds maximum possible timeout");
    return t;
  }, fr;
}
var Kt;
function vi() {
  if (Kt) return kt;
  Kt = 1;
  var e = cr(), r = Q(), t = hr(), l = fn(), f = hi(), o = $(), u = Function.prototype, n = Math.max, a = Math.min, i = Object.create;
  return o.maxAge = function(s, m, v) {
    var d, q, y, p;
    s = f(s), s && (d = i(null), q = v.async && o.async || v.promise && o.promise ? "async" : "", m.on("set" + q, function(c) {
      d[c] = setTimeout(function() {
        m.delete(c);
      }, s), typeof d[c].unref == "function" && d[c].unref(), p && (p[c] && p[c] !== "nextTick" && clearTimeout(p[c]), p[c] = setTimeout(function() {
        delete p[c];
      }, y), typeof p[c].unref == "function" && p[c].unref());
    }), m.on("delete" + q, function(c) {
      clearTimeout(d[c]), delete d[c], p && (p[c] !== "nextTick" && clearTimeout(p[c]), delete p[c]);
    }), v.preFetch && (v.preFetch === !0 || isNaN(v.preFetch) ? y = 0.333 : y = n(a(Number(v.preFetch), 1), 0), y && (p = {}, y = (1 - y) * s, m.on("get" + q, function(c, h, g) {
      p[c] || (p[c] = "nextTick", t(function() {
        var b;
        p[c] === "nextTick" && (delete p[c], m.delete(c), v.async && (h = e(h), h.push(u)), b = m.memoized.apply(g, h), v.promise && l(b) && (typeof b.done == "function" ? b.done(u, u) : b.then(u, u)));
      }));
    }))), m.on("clear" + q, function() {
      r(d, function(c) {
        clearTimeout(c);
      }), d = {}, p && (r(p, function(c) {
        c !== "nextTick" && clearTimeout(c);
      }), p = {});
    }));
  }, kt;
}
var Ht = {}, lr, Lt;
function mi() {
  if (Lt) return lr;
  Lt = 1;
  var e = C(), r = Object.create, t = Object.prototype.hasOwnProperty;
  return lr = function(l) {
    var f = 0, o = 1, u = r(null), n = r(null), a = 0, i;
    return l = e(l), {
      hit: function(s) {
        var m = n[s], v = ++a;
        if (u[v] = s, n[s] = v, !m)
          return ++f, f <= l ? void 0 : (s = u[o], i(s), s);
        if (delete u[m], o === m)
          for (; !t.call(u, ++o); ) ;
      },
      delete: i = function(s) {
        var m = n[s];
        if (m && (delete u[m], delete n[s], --f, o === m)) {
          if (!f) {
            a = 0, o = 1;
            return;
          }
          for (; !t.call(u, ++o); ) ;
        }
      },
      clear: function() {
        f = 0, o = 1, u = r(null), n = r(null), a = 0;
      }
    };
  }, lr;
}
var Wt;
function di() {
  if (Wt) return Ht;
  Wt = 1;
  var e = C(), r = mi(), t = $();
  return t.max = function(l, f, o) {
    var u, n, a;
    l = e(l), l && (n = r(l), u = o.async && t.async || o.promise && t.promise ? "async" : "", f.on(
      "set" + u,
      a = function(i) {
        i = n.hit(i), i !== void 0 && f.delete(i);
      }
    ), f.on("get" + u, a), f.on("delete" + u, n.delete), f.on("clear" + u, n.clear));
  }, Ht;
}
var Bt = {}, Jt;
function gi() {
  if (Jt) return Bt;
  Jt = 1;
  var e = A(), r = $(), t = Object.create, l = Object.defineProperties;
  return r.refCounter = function(f, o, u) {
    var n, a;
    n = t(null), a = u.async && r.async || u.promise && r.promise ? "async" : "", o.on("set" + a, function(i, s) {
      n[i] = s || 1;
    }), o.on("get" + a, function(i) {
      ++n[i];
    }), o.on("delete" + a, function(i) {
      delete n[i];
    }), o.on("clear" + a, function() {
      n = {};
    }), l(o.memoized, {
      deleteRef: e(function() {
        var i = o.get(arguments);
        return i === null || !n[i] ? null : --n[i] ? !1 : (o.delete(i), !0);
      }),
      getRefCount: e(function() {
        var i = o.get(arguments);
        return i === null || !n[i] ? 0 : n[i];
      })
    });
  }, Bt;
}
var sr, Zt;
function yi() {
  if (Zt) return sr;
  Zt = 1;
  var e = Xt(), r = Yt(), t = Bn();
  return sr = function(l) {
    var f = e(arguments[1]), o;
    return f.normalizer || (o = f.length = r(f.length, l.length, f.async), o !== 0 && (f.primitive ? o === !1 ? f.normalizer = Jn() : o > 1 && (f.normalizer = Zn()(o)) : o === !1 ? f.normalizer = ri()() : o === 1 ? f.normalizer = ti()() : f.normalizer = ni()(o))), f.async && ii(), f.promise && si(), f.dispose && ci(), f.maxAge && vi(), f.max && di(), f.refCounter && gi(), t(l, f);
  }, sr;
}
var bi = yi();
const Oi = /* @__PURE__ */ ln(bi);
export {
  Oi as memoize
};
