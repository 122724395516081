import {AuthOrigin, AuthViewName} from 'components/Auth/types';
import {sample} from 'effector';
import {createCommunicationStore} from 'lib/communication';
import {CommunicationOpenDialogParams} from 'lib/communication/types';
import {$appGetLanguage, createEvent} from 'lib/effector';
import {Language} from 'lib/language/types';
import {openAuthDialog} from 'models/auth/dialog';

const openConsultationDialog = createEvent<CommunicationOpenDialogParams>();

const {
  $store: $consultationDialog,
  openDialog,
  checkDialog: checkConsultationDialog,
  closeDialog: closeConsultationDialog,
  sendRequestFx: sendPageConsultationDataFx,
} = createCommunicationStore('consultation');

sample({
  clock: openConsultationDialog,
  fn: (getLanguage, openCommunicationDialogParams) => {
    if (getLanguage() === Language.PT_BR) {
      openAuthDialog({
        origin: AuthOrigin.GENERAL,
        pageUrl: openCommunicationDialogParams.pageUrl,
        source: openCommunicationDialogParams.source,
        view: AuthViewName.SIGN_IN_BY_PHONE,
      });
    } else {
      openDialog(openCommunicationDialogParams);
    }
  },
  source: $appGetLanguage,
});

export {
  $consultationDialog,
  openConsultationDialog,
  checkConsultationDialog,
  closeConsultationDialog,
  sendPageConsultationDataFx,
};
