import { useState as u, useCallback as s } from "react";
import { useBooleanState as r } from "../useBooleanState/index.js";
function c(o = !1) {
  const [a, t] = u(), e = r(o), n = s(
    (p) => {
      t(p), e.setTrue();
    },
    [e.setTrue]
  ), l = s(() => {
    t(void 0), e.setFalse();
  }, [e.setFalse]);
  return {
    payload: a,
    isOpen: e.value,
    openWithPayload: n,
    close: l,
    open: e.setTrue,
    toggle: e.toggle
  };
}
function S({ children: o, initiallyOpen: a }) {
  const t = c(a);
  return o(t);
}
export {
  S as PopupState,
  c as usePopupState
};
