import EventEmitter from 'component-emitter';
import { Level } from './Level';
import { EventType } from './Event';
export class Logger extends EventEmitter {
    constructor(name, level = Level.ALL) {
        super();
        this.fatal = this.createEventHandler(Level.FATAL);
        this.error = this.createEventHandler(Level.ERROR);
        this.warn = this.createEventHandler(Level.WARN);
        this.info = this.createEventHandler(Level.INFO);
        this.log = this.createEventHandler(Level.DEBUG);
        this.debug = this.createEventHandler(Level.DEBUG);
        this.trace = this.createEventHandler(Level.TRACE);
        this.name = name;
        this.level = level;
    }
    setLevel(level) {
        this.level = level;
    }
    getLevel() {
        return this.level;
    }
    logInternal(level, args = []) {
        if (this.level.value <= level.value) {
            this.emit(EventType.LOG, {
                args,
                level,
                name: this.name,
                timestamp: Date.now(),
            });
        }
    }
    createEventHandler(level) {
        return (...args) => this.logInternal(level, args);
    }
}
