export class Provider {
    constructor(config = {}) {
        this.config = config;
    }
    process(data) {
        const level = this.config[data.name] || this.config['*'];
        if (!level || level.value <= data.level.value) {
            this.processInternal(data);
        }
    }
}
