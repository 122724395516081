const t = (e) => ({ ok: !0, result: e }), r = (e) => e.ok === !0, a = (e) => ({ ok: !1, error: e }), n = (e) => e.ok === !1, u = (e) => e.ok === !0 ? Promise.resolve(e.result) : Promise.reject(e.error), c = (e, s) => s.ok === !0 ? s.result : e, l = (e) => {
  if (e.ok === !0)
    return e.result;
  throw e.error;
}, i = (e) => e.filter(r).map((s) => s.result), k = (e) => e.filter(n).map((s) => s.error), f = (e, s) => s.ok === !0 ? t(e(s.result)) : s, m = (e, s, o) => (
  // eslint-disable-next-line no-nested-ternary
  s.ok === !1 ? s : o.ok === !1 ? o : t(e(s.result, o.result))
), p = (e, s) => s.ok === !0 ? s : a(e(s.error)), d = (e, s) => s.ok === !0 ? e(s.result) : s, h = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  andThen: d,
  asPromise: u,
  err: a,
  errors: k,
  isErr: n,
  isOk: r,
  map: f,
  map2: m,
  mapError: p,
  ok: t,
  successes: i,
  withDefault: c,
  withException: l
}, Symbol.toStringTag, { value: "Module" }));
export {
  u as a,
  f as b,
  d as c,
  n as d,
  a as e,
  m as f,
  c as g,
  r as h,
  h as i,
  k as j,
  p as m,
  t as o,
  i as s,
  l as w
};
