import {noop} from '@joomcode/deprecated-utils/function';
import {Client} from 'lib/client';
import {ContactUsDestination} from 'lib/selfService/types';

type RequestPayload = {
  [key: string]: unknown;
  destination?: ContactUsDestination;
  text?: string;
};

export function contactUs(client: Client, body: RequestPayload): Promise<void> {
  return client.mainApi.post('/selfService/contactUs', {body}).then(noop);
}
