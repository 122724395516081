import {SelectField} from 'components/FinalForm/SelectMuiField';
import {useDevicevars} from 'lib/devicevars';
import {COMPANY_EMPLOYEES_NUMBERS_ORDER} from 'lib/user/companyEmployeesNumber';
import {formatEmployeesNumber} from 'lib/user/companyEmployeesNumber/messages';
import React from 'react';
import {useIntl} from 'react-intl';
import {fieldMessages} from '../../messages';
import {FieldProps} from '../../types';

export const CompanyEmployeesNumber = ({fieldName, required}: FieldProps) => {
  const intl = useIntl();
  const {shouldReverseRegistrationFieldsOptions} = useDevicevars();

  const sortedCompanyEmployeesOrder = shouldReverseRegistrationFieldsOptions
    ? COMPANY_EMPLOYEES_NUMBERS_ORDER.slice().reverse()
    : COMPANY_EMPLOYEES_NUMBERS_ORDER;

  return (
    <SelectField
      label={intl.formatMessage(fieldMessages.companyEmployeesNumber)}
      name={fieldName}
      options={sortedCompanyEmployeesOrder.map((value) => ({
        label: formatEmployeesNumber(value, intl),
        value,
      }))}
      required={required}
    />
  );
};
