import {AuthViewName, AuthByPhoneViewName} from 'components/Auth/types';
import {sample} from 'effector';
import {analyticsDataLayer, analyticsSendEvent} from 'lib/effector/analytics';
import {openSsRegistrationDialog} from 'models/ssRegistrationDialog';
import {$userNotAnonymous} from 'models/user';
import Router from 'next/router';
import {authSuccess} from '../index';
import {
  $authDialog,
  $authRedirectUri,
  changeAuthDialogView,
  changeAuthPhoneDialogView,
  closeAuthDialog,
  closeAuthDialogInternal,
  openAuthDialog,
  openAuthDialogInternal,
  authDialogSignInByEmailFx,
  authDialogSignUpByEmailFx,
  authDialogRequestRecoveryFx,
  authDialogSignInByPhoneFx,
  authDialogSendOtpFx,
} from './index';

$authDialog
  .on(openAuthDialogInternal, (state, event) => ({
    ...state,
    origin: event.origin,
    pageUrl: event.pageUrl,
    source: event.source,
    view: event.view,
    visible: true,
  }))
  .on(changeAuthDialogView, (state, view) => ({
    ...state,
    requestRecoveryError: undefined,
    sendOtpError: undefined,
    signInError: undefined,
    signUpError: undefined,
    view,
  }))
  .on(changeAuthPhoneDialogView, (state, phoneView) => ({
    ...state,
    phoneView,
    requestRecoveryError: undefined,
    sendOtpError: undefined,
    signInError: undefined,
    signUpError: undefined,
  }))
  .reset([closeAuthDialogInternal, authSuccess]);

$authDialog
  .on(authDialogSignInByEmailFx, (state) => ({
    ...state,
    signInError: undefined,
    signInPending: true,
  }))
  .on(authDialogSignInByEmailFx.doneData, (state) => ({
    ...state,
    signInPending: false,
  }))
  .on(authDialogSignInByEmailFx.failData, (state, error) => ({
    ...state,
    signInError: error,
    signInPending: false,
  }));

$authDialog
  .on(authDialogSignUpByEmailFx, (state) => ({
    ...state,
    signUpError: undefined,
    signUpPending: true,
  }))
  .on(authDialogSignUpByEmailFx.doneData, (state) => ({
    ...state,
    signUpPending: false,
  }))
  .on(authDialogSignUpByEmailFx.failData, (state, error) => ({
    ...state,
    signUpError: error,
    signUpPending: false,
  }));

$authDialog
  .on(authDialogRequestRecoveryFx, (state) => ({
    ...state,
    requestRecoveryError: undefined,
    requestRecoveryPending: true,
  }))
  .on(authDialogRequestRecoveryFx.done, (state, {params}) => ({
    ...state,
    requestRecoveryEmail: params.email,
    requestRecoveryPending: false,
    view: AuthViewName.PASSWORD_RECOVERY_SENT,
  }))
  .on(authDialogRequestRecoveryFx.failData, (state, error) => ({
    ...state,
    requestRecoveryError: error,
    requestRecoveryPending: false,
  }));

$authDialog
  .on(authDialogSendOtpFx, (state) => ({
    ...state,
    sendOtpError: undefined,
    sendOtpLoading: true,
  }))
  .on(authDialogSendOtpFx.done, (state, {params: sendOtpData, result: {retryAfterMs}}) => ({
    ...state,
    phoneView: AuthByPhoneViewName.OTP,
    retryAfterMs,
    sendOtpData,
    sendOtpLoading: false,
  }))
  .on(authDialogSendOtpFx.failData, (state, error) => ({
    ...state,
    sendOtpError: error,
    sendOtpLoading: false,
  }));

$authDialog
  .on(authDialogSignInByPhoneFx, (state) => ({
    ...state,
    signInError: undefined,
    signInPending: true,
  }))
  .on(authDialogSignInByPhoneFx.doneData, (state) => ({
    ...state,
    signInPending: false,
  }))
  .on(authDialogSignInByPhoneFx.failData, (state, error) => ({
    ...state,
    signInError: error,
    signInPending: false,
  }));

$authRedirectUri.reset(closeAuthDialogInternal);
$authRedirectUri.on(openAuthDialogInternal, (_, event) => {
  return {
    value: event.redirectUri,
  };
});

sample({
  clock: authSuccess,
  fn: ([{value: redirectUri}, userNotAnonymous]) => {
    if (redirectUri) {
      Router.push(redirectUri);
    } else if (userNotAnonymous) {
      openSsRegistrationDialog();
    }
  },
  source: [$authRedirectUri, $userNotAnonymous] as const,
});

analyticsDataLayer(
  sample({
    clock: openAuthDialogInternal,
    source: $authDialog,
  }),
  ({source}) => ({
    event: 'signUpOpen',
    source,
  }),
);

analyticsSendEvent(
  sample({
    clock: openAuthDialogInternal,
    source: $authDialog,
  }),
  ({source, pageUrl}) => ({
    payload: {pageUrl: pageUrl ?? '', source: source ?? ''},
    type: 'signUpOpen',
  }),
);

analyticsSendEvent(
  sample({
    clock: closeAuthDialog,
    source: $authDialog,
  }),
  ({source, pageUrl}) => ({
    payload: {pageUrl: pageUrl ?? '', source: source ?? ''},
    type: 'signUpClose',
  }),
);

sample({
  clock: openAuthDialog,
  fn: (userNotAnonymous, params) => {
    if (userNotAnonymous) {
      openSsRegistrationDialog();
    } else {
      openAuthDialogInternal(params);
    }
  },
  source: $userNotAnonymous,
});

sample({
  clock: closeAuthDialog,
  target: closeAuthDialogInternal,
});
