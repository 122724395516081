import { useRef as u, useEffect as f } from "react";
function o(t, r) {
  const e = u(!1);
  f(() => {
    if (e.current)
      return t();
    e.current = !0;
  }, r);
}
export {
  o as useUpdateEffect
};
