import { levelMapping } from '../sentry';
import { Provider } from '../providers/Provider';
import { stringify } from '../utils';
export class SentryBreadcrumbsProvider extends Provider {
    constructor(sentry, config) {
        super(config);
        this.sentry = sentry;
    }
    processInternal({ name, level, meta, args }) {
        let message;
        const maybeEcsInputMessage = args[0];
        if (maybeEcsInputMessage && typeof maybeEcsInputMessage === 'object') {
            message = maybeEcsInputMessage.message || '';
        }
        else {
            message = args.map(stringify).join(' ');
        }
        this.sentry.addBreadcrumb({
            level: levelMapping[level.name],
            category: name,
            message,
            data: meta,
            timestamp: Date.now(),
        });
    }
}
