import {createStore, createEvent} from 'lib/effector';
import {Promotion} from 'lib/promotion/types';

type PromotionsState = {
  promotionById: Record<string, Promotion | undefined>;
  promotions: Promotion[];
};

const intialState: PromotionsState = {
  promotionById: {},
  promotions: [],
};

export const $catalogPromotions = createStore('selfServicePromotions', intialState);

export const setCatalogPromotions = createEvent<Promotion[]>();

export const resetCatalogPromotions = createEvent();
