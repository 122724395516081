import { identity as f } from "../../function/identity/index.js";
function p(o, n, u = f) {
  return o.reduce(
    (r, t, e) => (r[n(t, e)] = u(t, e), r),
    {}
  );
}
export {
  p as arrayToObject
};
