import {sample} from 'effector';
import {$appGetClient} from 'lib/effector';
import {$authDialog} from 'models/auth/dialog';
import {$userAnonymous} from 'models/user';
import {openTimeoutedRegistation} from '.';
import {openSsRegistrationDialog} from '../';

sample({
  clock: openTimeoutedRegistation,
  fn: ([_, isUserAnonymous, authDialog], {pageUrl, source}) => {
    if (isUserAnonymous && !authDialog.visible) {
      openSsRegistrationDialog({pageUrl, source});
    }
  },
  source: [$appGetClient, $userAnonymous, $authDialog] as const,
});
