import {isRecord, isString} from 'lib/guards';
import {SearchParams} from 'lib/search/types';
import {buildSearchPath} from 'lib/search/url';
import {appendQueryToPath, buildQueryString} from 'lib/url';
import {ReverseUrl, PageName} from '../types';

function typeSafe<T extends Record<Exclude<PageName, 'unknown' | 'error'>, unknown>>(value: T) {
  return value;
}

export const reverseUrl = typeSafe({
  about(): string {
    return '/about';
  },

  addCustomer({source}: unknown): string {
    const query = buildQueryString({source});
    return appendQueryToPath('/add-customer', query);
  },

  auth(nextPage?: string): string {
    const query = nextPage ? buildQueryString({nextPage}) : undefined;
    return appendQueryToPath('/auth', query);
  },

  authRecoverPassword(token: string): string {
    return `/auth/recover-password/${token}`;
  },

  authResetPassword(token: string): string {
    return `/auth/reset-password/${token}`;
  },

  authToken(): string {
    return `/auth/token/signin`;
  },

  blog(): string {
    return '/blog';
  },

  blogPost({slug}: ReverseUrl['blogPost']): string {
    return `/blog/posts/${slug}`;
  },

  blogPostsByTag({tag}: ReverseUrl['blogPosts']): string {
    return `/blog/category/${tag}`;
  },

  cart({source}: ReverseUrl['cart']): string {
    const query = buildQueryString({source});

    return appendQueryToPath('/cart', query);
  },

  checkout({channelType, cartItemIds}: ReverseUrl['checkout']): string {
    const query = buildQueryString({cartItemIds: Array.from(cartItemIds), channelType});
    return appendQueryToPath('/checkout', query);
  },

  iframeSearch(props?: SearchParams | string): string {
    return `/iframe${this.search(props)}`;
  },

  loyaltyProgram(): string {
    return '/loyalty-program';
  },

  main(): string {
    return '/';
  },

  order(orderId: string, {source}: ReverseUrl['orders']): string {
    const query = buildQueryString({source});
    return appendQueryToPath(`/orders/${orderId}`, query);
  },

  orders({source}: ReverseUrl['orders']): string {
    const query = buildQueryString({source});
    return appendQueryToPath('/orders', query);
  },

  partner(partnerId: string): string {
    return `/partners/${partnerId}`;
  },
  partnerBr(partnerId: string): string {
    return `/partnerBr/${partnerId}`;
  },

  partners(): string {
    return '/partners';
  },

  payments(): string {
    return '/payments';
  },

  policy(): string {
    return '/policy';
  },

  product({productId}: ReverseUrl['product']): string {
    return `/products/${productId}`;
  },

  productBr({productId}: ReverseUrl['product']): string {
    return `/productsBr/${productId}`;
  },

  promotion({promotionId}: ReverseUrl['promotion']): string {
    return `/promotions/${promotionId}`;
  },

  referral(): string {
    return '/referral';
  },

  rfq({source}: ReverseUrl['rfq']): string {
    const query = buildQueryString({source});
    return appendQueryToPath('/rfq', query);
  },

  sampleCheckout({productId, source}: ReverseUrl['sampleCheckout']): string {
    const query = buildQueryString({source});

    return appendQueryToPath(`/checkout/sample/${productId}`, query);
  },

  search(props?: SearchParams | string): string {
    let path;

    if (isString(props)) {
      path = props;
    } else if (isRecord(props)) {
      path = buildSearchPath(props);
    }

    return `/search${path ? `/${path}` : ''}`;
  },

  support(): string {
    return '/support';
  },

  terms(): string {
    return '/terms';
  },
});
