export enum OriginalFilterType {
  CATEGORIES = 'categories',
  CHECKBOX = 'checkbox',
  COLORS = 'colors',
  MONEY_RANGE = 'moneyRange',
  SEARCH_TAG = 'searchTag',
  STORES = 'stores',
  TREE = 'tree',
}

export enum SearchPageView {
  DEFAULT = 'default',
  MAIN = 'main',
}
