import { useState as u, useMemo as a } from "react";
function l(o) {
  const [t, e] = u(o);
  return a(
    () => ({
      setFalse: () => e(!1),
      setTrue: () => e(!0),
      value: t,
      toggle: () => e((s) => !s)
    }),
    [t]
  );
}
export {
  l as useBooleanState
};
