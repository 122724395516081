import { objectKeys as s } from "../../object/keys/index.js";
function a(c, o) {
  return new Promise((i, r) => {
    try {
      const t = window.document.getElementsByTagName("script")[0], e = window.document.createElement("script");
      o && s(o).forEach((n) => {
        e.setAttribute(n, o[n]);
      }), e.src = c, e.async = !0, e.type = "text/javascript", e.onload = () => setTimeout(i, 0), e.onerror = r, t.parentNode && t.parentNode.insertBefore(e, t);
    } catch (t) {
      r(t);
    }
  });
}
export {
  a as loadAsyncScript
};
