import {PageName} from '../types';

// Don't rename. Is used in `apps/pro-client/eslint-local-rules.js`
const PAGE_TYPE_BY_PATHNAME: Record<string, PageName> = {
  /* eslint-disable @typescript-eslint/naming-convention */
  '/': 'main',
  '/_error': 'error',
  '/about': 'about',
  '/add-customer/[[...params]]': 'addCustomer',
  // '/analytics': 'main',
  '/auth': 'auth',
  '/auth/recover-password/[token]': 'authRecoverPassword',
  '/auth/reset-password/[token]': 'authResetPassword',
  '/auth/token/signin': 'authToken',
  '/blog': 'blog',
  '/blog/category/[tag]': 'blogPostsByTag',
  '/blog/posts/[slug]': 'blogPost',
  '/cart': 'cart',
  '/checkout': 'checkout',
  '/checkout/sample/[productId]': 'sampleCheckout',
  '/loyalty-program': 'loyaltyProgram',
  '/orders': 'orders',
  '/orders/[orderId]': 'order',
  '/partners': 'partners',
  '/partners/[partnerName]': 'partner',
  '/partnersBr/[partnerName]': 'partnerBr',
  '/payments': 'payments',
  '/policy': 'policy',
  '/products/[productId]': 'product',
  '/productsBr/[productId]': 'product',
  '/promotions/[promotionId]': 'promotion',
  '/referral': 'referral',
  '/rfq': 'rfq',
  '/search/[[...filters]]': 'search',
  '/support': 'support',
  '/terms': 'terms',

  /* eslint-enable @typescript-eslint/naming-convention */
};

export function getPageNameByPathname(pathname: string): PageName {
  return PAGE_TYPE_BY_PATHNAME[pathname] ?? 'unknown';
}
