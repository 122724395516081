import { removeCircularProperties } from '../utils';
const isDev = process.env.NODE_ENV !== 'production';
const STRINGIFY_SPACE = isDev ? 2 : undefined;
export function stringify(item) {
    if (typeof item !== 'object' || item === null) {
        return String(item);
    }
    try {
        return JSON.stringify(item, null, STRINGIFY_SPACE);
    }
    catch (error) {
        if (isDev) {
            // eslint-disable-next-line no-console
            console.error('Error on stringify log json', item);
            throw error;
        }
        try {
            return JSON.stringify(removeCircularProperties(item, { replacer: 'CIRCULAR_PROPERTY' }), null, STRINGIFY_SPACE);
        }
        catch {
            return 'stringify error';
        }
    }
}
