import {DataState} from '@joomcode/deprecated-utils/dataState';
import * as api from 'api/order';
import {createClientEffect, createStore} from 'lib/effector';
import {FullOrder} from 'lib/order/types';

export type OrderState = {
  data: FullOrder | undefined;
  dataState: DataState;
  error?: Error;
};

export const $order = createStore<OrderState>('order', {
  data: undefined,
  dataState: DataState.IDLE,
});

export const loadOrderFx = createClientEffect(api.loadOrder);
