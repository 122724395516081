import { memoize as n } from "../memoize/index.js";
var a = /* @__PURE__ */ ((e) => (e.IDLE = "idle", e.LOADING = "loading", e.LOADED = "loaded", e.FAILED = "failed", e))(a || {});
const s = (e) => e === "loaded" || e === "loading", i = {
  failed: 0,
  loading: 1,
  idle: 2,
  loaded: 3
}, r = n(
  (...e) => e.reduce(
    (o, d) => i[d] < i[o] ? d : o,
    "loaded"
    /* LOADED */
  ),
  { length: !1 }
), g = n(
  (...e) => r(
    ...e.reduce((o, [d, l]) => l ? [...o, d] : o, [])
  ),
  { length: !1, normalizer: JSON.stringify }
);
export {
  a as DataState,
  g as getConsolidatedAccessDependentDataState,
  r as getConsolidatedDataState,
  s as isLoadingOrLoaded
};
