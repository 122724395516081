import { identity as s } from "./identity/index.js";
import { isNotNullish as x } from "./isNotNullish/index.js";
import { isNullish as h } from "./isNullish/index.js";
import { noop as n } from "./noop/index.js";
import { t as o } from "../index-DhMthD6W.mjs";
const p = o.pipe, i = o.pipeWith;
export {
  s as identity,
  x as isNotNullish,
  h as isNullish,
  n as noop,
  p as pipe,
  i as pipeWith
};
